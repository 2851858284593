import { Component, OnInit, TemplateRef } from '@angular/core';
import { ProductosService, brrCarrito } from '../../services/productos.service';
import { AdminService } from '../../services/admin.service';
import { FacturasDetalleTemp } from '../../Entities/FacturaDetalle';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ChatService } from '../../services/chat.service';
import { getMessaging, getToken, deleteToken } from 'firebase/messaging';
import { ToastrService } from 'ngx-toastr';
import { CommunicationServiceService } from '../../services/communication-service.service';
import { FireBaseCredencials } from '../../helpers/Util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  printerSettingsModal?: BsModalRef;
  printerForm: FormGroup;
  private printerSettingsWitdh = 'PrinterSettingsWitdh';

  msg = getMessaging();
  public token: any;
  public usuSesion: any;
  public usuFoto: any;
  public totalItems = 0;
  public totalItemsStore = 0;
  public grupos: any;
  public usuId: any;
  public productos: any;
  public detalleTemp: FacturasDetalleTemp[];
  public subTotal: number;
  public subTotalTemp: number;
  public Badges = false;
  public apitoken: any;
  public countNtf = 0;
  public carEmpty = false;
  public empID: any;
  public isAdmin = true;

  myEmpId: any;

  datoBorrar: brrCarrito = {
    PedPosicion: 0,
    PieID: 0,
    PedUsuID: 0,
    EmpID: 0,
    PedCantidad: 0,
    PedPrecio: 0,
    PedPorcientoIImpuestos: 0,
    PedMontoImpuestos: 0,
    PedDescuentoPorciento: 0,
    PedMontoDescuento: 0,
    PedMontoSubTotal: 0,
    PedMontoTotal: 0,
    PedMontoEnvio: 0,
    //PedFecha: string,
    PedImagen: '',
    ProNombre: '',
    PedEstatus: 0,
    PedNumTracking: '',
    UsuID: 0,
    PedNota: '',
  };

  constructor(
    private adminService: AdminService,
    private communicationService: CommunicationServiceService,
    private _chatService: ChatService,
    private _productosService: ProductosService,
    private router: Router,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.communicationService.componentMethodCalled.subscribe((res) => {
      console.log('kl');
      this.getGrupo();
      this.getFacturaTemp();
    });

    this.usuFoto = localStorage.getItem('usuFoto');
    this.token = localStorage.getItem('token');
    this.usuSesion = localStorage.getItem('usuSesion');
    this.usuId = localStorage.getItem('usuId');
    this.apitoken = localStorage.getItem('firebaseToken');
    this.empID = localStorage.getItem('empID');
    if (
      localStorage.getItem('empID') == null ||
      localStorage.getItem('empID') == '0'
    ) {
      this.isAdmin = false;
      this.empID = 0;
    } else {
      this.isAdmin = true;
      this.getFacturaTemp();
    }

    this.myEmpId = this.empID == 0 ? this.usuId : this.empID;

    if (localStorage.getItem('token')) {
      this.getGrupo();
    }

    this.printerForm = this.fb.group({
      printerwidth: [localStorage.getItem(this.printerSettingsWitdh) || '', Validators.required]
    });

    //Esto no lo vamos a llamar por ahora, no se estan haciendo pedidos
    // this._productosService.getPedidosTemp().subscribe(
    //   res => {

    //     this.productos = res;
    //     this.totalItems = this.productos.length;
    //     if (this.totalItems > 0) {

    //       this.carEmpty = true;
    //     }
    //     this.subTotal = res.reduce((sum, item) => sum + item.pedMontoTotal, 0);
    //   },
    //   err => {
    //     console.error(err)
    //   }
    // )

    /*onMessage(this.msg, (payload) => {
        this.getGrupo()
        this.Badges= true;
        console.log('Msg', payload);
        let bdy = payload.notification?.body;
        let title = payload.notification?.title;
        let Idgrupo = payload.data;
        this.showHTMLMessage(bdy,title);
      })*/
  }

  showHTMLMessage(message: any, title: any) {
    this.toastr.success(message, title, {
      enableHtml: true,
      timeOut: 3500,
      closeButton: true,
    });
    /*.onTap
    .pipe(take(1))
    .subscribe(() => this.toasterClickedHandler());
    //window.location.reload();*/
  }

  /* toasterClickedHandler() {
     console.log('Toastr clicked');
     this.router.navigateByUrl('159/0/starlet/https:%2F%2Fvehiclepartimagenes.blob.core.windows.net%2Fcarros%2FrUDERv83vkGf0HDKCkZzeg.png/32');
   }*/

  getGrupo() {
    console.log(this.router.url)
    this._chatService.getGrupo().subscribe(
      (res) => {
        if (res.length > 5) this.grupos = res.slice(0, 4);
        else this.grupos = res;

        this.countNtf = res.filter(
          (count) =>
            count.menGrupLeido == false &&
            (count.lastTo == this.empID || count.lastTo == this.usuId)
        ).length;
        if (this.countNtf > 0) this.Badges = true;
      },
      (err) => {
        console.log(err);
        if (
          (err.status == 401 || err.status == 0 || err.status == 504) &&
          localStorage.getItem('token') != null
        ) {
          //window.localStorage.removeItem('token');
          //window.location.reload();
          if(this.router.url != "/"){
            this.router.navigateByUrl('/Relogin')
          }

        }
      }
    );
  }

  getFacturaTemp() {
    this.adminService.getFacturaDetalleTemp().subscribe(
      (res) => {
        this.detalleTemp = res;
        console.log(this.detalleTemp);
        this.totalItemsStore = this.detalleTemp.length;
        if (this.totalItemsStore > 0) this.carEmpty = true;
        else this.carEmpty = false;

        this.subTotalTemp = res.reduce(
          (sum, item) => sum + item.facSubMontoTotal,
          0
        );
      },
      (err) => {
        console.error(err);
      }
    );
  }

  logout(): void {
    try {
      getToken(this.msg, { vapidKey: FireBaseCredencials.vapidKeyToken }).then(
        (tok) => {
          this.usuId = localStorage.getItem('usuId');
          fetch(
            'https://iid.googleapis.com/iid/v1/' +
              tok +
              '/rel/topics/' +
              this.usuId,
            {
              method: 'DELETE',
              headers: new Headers({
                Authorization: FireBaseCredencials.vpauth,
              }),
            }
          )
            .then(async (resUsu) => {
              if (resUsu.status == 200) {
                console.log('BorradoUser');
                if (localStorage.getItem('empID')) {
                  getToken(this.msg, {
                    vapidKey: FireBaseCredencials.vapidKeyToken,
                  }).then((tok) => {
                    this.empID = localStorage.getItem('empID');
                    fetch(
                      'https://iid.googleapis.com/iid/v1/' +
                        tok +
                        '/rel/topics/emp' +
                        this.empID,
                      {
                        method: 'DELETE',
                        headers: new Headers({
                          Authorization: FireBaseCredencials.vpauth,
                        }),
                      }
                    )
                      .then(async (resEmp) => {
                        if (resEmp.status == 200) {
                          console.log('BorradoEMp');
                        } else {
                          throw (
                            'Error Unsubscribing to Emp: ' +
                            resEmp.status +
                            ' - ' +
                            (await resEmp.json())
                          );
                        }
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  });
                }
                console.log('delete token');
              } else {
                throw (
                  'Error Unsubscribing to Usu: ' +
                  resUsu.status +
                  ' - ' +
                  (await resUsu.json())
                );
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      );
    } catch (error) {
      console.error(error);
    }
    window.localStorage.removeItem('usuId');
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('usuSesion');
    window.localStorage.removeItem('usuFoto');
    window.localStorage.removeItem('firebaseToken');
    window.localStorage.removeItem('empID');
    window.location.reload();
    // deleteToken(this.msg).then(() => {
    //   console.log("delete token");
    //   window.localStorage.removeItem('usuId');
    //   window.localStorage.removeItem('token');
    //   window.localStorage.removeItem('usuSesion');
    //   window.localStorage.removeItem('usuFoto');
    //   window.localStorage.removeItem('firebaseToken');
    //   window.localStorage.removeItem('empID');
    //   window.location.reload();
    // }).catch((err) => {
    //   console.log('Unable to delete token. ', err);
    // });
  }

  borrarItem(item: any) {
    this.datoBorrar.PieID = item;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this._productosService.borrartCarrito(item).subscribe(
          (res) => {
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          },
          (err) => {
            if (err.status == 200) {
              // this.ngOnInit();
              // window.location.reload();
              this._productosService.getPedidosTemp().subscribe(
                (res) => {
                  this.productos = res;
                  this.totalItems = this.productos.length;
                  this.subTotal = res.reduce(
                    (sum, item) => sum + item.pedMontoTotal,
                    0
                  );
                },
                (err) => {
                  if (
                    err.status == 401 &&
                    window.localStorage.getItem('token')
                  ) {
                    //window.localStorage.removeItem('token');
                    //window.location.reload();
                    this.router.navigateByUrl('/Relogin');
                  }
                  console.log(err);
                }
              );
            }
            console.log(err);
          }
        );
      }
    });
  }

  borrarFacturaTemp(item: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        var loa = Swal.fire({
          title: 'Loading',
          showConfirmButton: false,
          allowOutsideClick: false,
          html: 'Please wait',

          timerProgressBar: true,
        });
        this.adminService.DeleteFacturaDetalleTemp(item).subscribe(
          (res) => {
            this.getFacturaTemp();
            this.communicationService.callComponentMethod('deleteFromcar');
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Has been deleted.',
              showConfirmButton: false,
              timer: 1000,
            });
          },
          (err) => {
            console.log(err);
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: err.error,
              showConfirmButton: true,
            });
          }
        );
      }
    });
  }

  openPrinterSettingsModal(template: TemplateRef<void>) {
    this.printerSettingsModal = this.modalService.show(template);
  }

  onSubmitPrinterSettings(): void {
    if (this.printerForm.valid) {
      this.printerSettingsModal.hide();
      localStorage.setItem(this.printerSettingsWitdh, this.printerForm.value.printerwidth);
    }
  }
}
