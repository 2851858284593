<div id="content">
  <div class="d-flex align-items-center">
    <nav
      style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
      aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/Dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Cars</li>
      </ol>
    </nav>
  </div>
</div>
<hr>
<div class="row py-2 ms-0 pxs-0">
  <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
    <div class="d-flex flex-row bd-highlight me-0 my-0">
      <div class="pe-2 pt-2 ps-0 bd-highlight">
        <h2 class="text-primary">Cars</h2>
      </div>
      <div class="py-2 px-0 bd-highlight">
        <button type="button" class="btn btn-outline-primary btn-lg" [routerLink]="['/Dashboard/CrearCar']">add <i
            class="fa fa-plus"></i></button>
      </div>
    </div>

    <div class="p-2 bd-highlight">
      <h3 class="text-primary">Total Cars: {{count | number}}</h3>
    </div>
  </div>
</div>

<div class="row pe-3">
  <form [formGroup]="myForm">
    <div class="row">

      <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item mt-3">
        <h6 class="text-primary">Year</h6>

        <ng-multiselect-dropdown appSelectAutoFoucs name="year" formControlName="year" [(ngModel)]="this.currentYear"
          [placeholder]="'Select year'" [settings]="{      
            closeDropDownOnSelection: true,
            singleSelection: true,
            idField: 'year',
            textField: 'year',
            itemsShowLimit: 1,
            allowSearchFilter: true}" [data]="years"></ng-multiselect-dropdown>


      </div>


      <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item mt-3 ">
        <h6 class="text-primary ">Make</h6>

        <ng-multiselect-dropdown appSelectAutoFoucs [disabled]="marListo" name="marcaf" formControlName="marcaf"
          [(ngModel)]="this.currentMarca" [placeholder]="'Select make'" [settings]="{   
          closeDropDownOnSelection: true,   
          singleSelection: true,
          idField: 'marID',
          textField: 'marDescripcion',
          itemsShowLimit: 1,
          allowSearchFilter: true}" [data]="marca" (onDeSelect)="UnSelectObject($event)"
          (onSelect)="cargarModelo($event)"></ng-multiselect-dropdown>

      </div>

      <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item mt-3">
        <h6 class="text-primary">Model</h6>

        <ng-multiselect-dropdown appSelectAutoFoucs name="modelof" formControlName="modelof" [(ngModel)]="this.currentModelo"
          [placeholder]="'Select model'" [settings]="{      
          closeDropDownOnSelection: true, 
          singleSelection: true,
          idField: 'modID',
          textField: 'modDescripcion',
          itemsShowLimit: 1,
          allowSearchFilter: true}" 
          [data]="modelo"></ng-multiselect-dropdown>

      </div>

      <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item mt-3">
        <h6 class="text-primary">VIN</h6>
        <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Vin" aria-label="Recipient's username" id="vin"
            aria-describedby="button-addon2">
          <button class="btn btn-primary text-white" (click)="handlePageChange(1)">Seach</button>
          <button class="btn btn-danger text-white" (click)="buscarcarro(true)">x</button>
        </div>
      </div>

      <hr style="border: black; border-width: 1px;" />

    </div>
  </form>


</div>

<div class="row flex-nowrap ps-2 pe-0">
  <div class="row">
    <div class="d-flex justify-content-center" *ngIf="cargar; else elseBlock">
      <div class="spinner-border text-primary  m-3" role="status" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div *ngIf="count == 0" class="p-2 px-0 d-flex justify-content-center">
      <div class="d-flex flex-column justify-content-center">
        <h3 class="d-flex justify-content-center">Data not found.</h3>
        <img class="d-flex justify-content-center" src="../../../assets/img/phvp.png" width="200" height="200" alt="">
      </div>
    </div>

    <ng-template #elseBlock>

      <div class="card mb-3"
        *ngFor="let carro of carros | paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count  }; let i = index">
        <div class="row g-0">
          <div class="col-4 col-sm-5 col-md-3 col-lg-3 d-flex align-items-center">
            <img src="{{carro.imagen1}}" class="card-img-top ml-5 mt-2 mb-2 fit-image img-fluid rounded-start" alt="...">
              <span style="border: 0.5px solid #404040; border-radius: 30px; position: absolute; top: 0; left: 10; margin: 20px;" [style.background-color]="setCalor(carro.carColor)"> 
                <i [style.color]="setCalor(carro.carColor)">{{carro.carColor}}</i>
              </span>

          </div>
          <div class="col-8 col-sm-8 col-md-9 col-lg-9 d-flex align-items-center">
            <div class="card-body">
              <a [routerLink]="['/Dashboard/editarCarro',carro.carId]">
                <p class="Nombre-text">{{getAnoFabricacion(carro)}} {{carro.marcaString}} {{carro.modeloString}} {{carro.carVersion}}</p>
              </a>
              <hr>

              <div class="row g-0">
                <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex align-items-center">
                  <div class="card-body">
                    <p class="secom-text"><small class="text-muted">VIN: {{carro.carVIN}}</small></p>
                    <p class="secom-text"><small class="text-muted">Mileage: {{carro.carKilometraje}}</small></p>
                    <p class="secom-text"><small class="text-muted">Tranmicion Info: {{carro.carTranmicionInfo}}</small>
                    </p>
                  </div>

                </div>

                <div class="col-6 col-sm-6 col-md-5 col-lg-5 d-flex align-items-center">
                  <div class="card-body">
                    <p class="secom-text"><small class="text-muted">Model's Motor: {{carro.carModeloMotor}}</small></p>
                    <p class="secom-text"><small class="text-muted">Cilindros: {{carro.carCilindros}}</small></p>
                    <p class="secom-text"><small class="text-muted">Total Cost: {{carro.carCostoTotal
                        |currency}}</small></p>
                  </div>

                </div>


              </div>

              <div class="d-grid gap-2 d-md-flex justify-content-md-star">
                <button *ngIf="carro.carId != currentPrintCar" class="btn btn-success btn-lg" [routerLink]="['/Dashboard/editarCarro',carro.carId]"> <i
                    class="fa fa-edit"></i></button>
                <button *ngIf="carro.carId != currentPrintCar" class="btn btn-success btn-lg" (click)="OpenPrint(carro.carId)"> <i class="fa fa-print"></i></button>
                <button *ngIf="carro.carId == currentPrintCar" class="btn btn-danger btn-lg" (click)="OpenPrint(carro.carId)"> <i class="fa fa-times"></i></button>
                <button *ngIf="carro.carId == currentPrintCar" class="btn btn-success btn-lg" (click)="redirectToPrint(carro.carId)">Print Labels</button>
                <div *ngIf="carro.carId == currentPrintCar" class="input-group" style="width: 400px;">
                  <input id="QuantityToPrint{{carro.carId}}_ip" type="number" class="form-control" placeholder="Quantity">
                  <button  class="btn btn-success btn-lg" (click)="addAndPrint(carro.carId)"> Add and Print</button>
                </div>
                <button *ngIf="carro.carId != currentPrintCar"class="btn btn-danger btn-lg" (click)="deleteCar(carro.carId)"> <i class="fa fa-trash"></i></button>
              </div>

            </div>

          </div>


        </div>
        <div id="{{carro.carId}}" style="display: none;">

        </div>
      </div>

    </ng-template>
    <style>
      .my-pagination /deep/ .ngx-pagination .current {
        background: #008c44;
      }
    </style>
    <div class="col-12 mt-3 d-flex justify-content-center">
      <pagination-controls class="my-pagination" previousLabel="Prev" nextLabel="Next" [responsive]="true"
        (pageChange)="handlePageChange($event)">
      </pagination-controls>
    </div>