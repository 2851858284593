import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProductosService } from '../../services/productos.service';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-print-piece',
  templateUrl: './print-piece.component.html',
  styleUrls: ['./print-piece.component.scss'],
})
export class PrintPieceComponent implements OnInit {
  piece: any;
  printerWidth: number;

  private printerSettingsWitdh = 'PrinterSettingsWitdh';

  constructor(
    private _productosService: ProductosService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getPrinterSettingsWitdh();
    this._route.params.subscribe((params) => {
      let id = params.id;
      this.getPiece(id);
    });
  }

  getPiece(id: string): void {
    this._productosService.getMiProducto(id).subscribe(
      (response) => {
        this.piece = response;
        if(this.printerWidth !== null) {
          setTimeout(() => {
            this.generateLabel();
          }, 1000);
        }
      },
      (error) => {
        console.log(<any>error);
      }
    );
  }

  generateLabel(): void {
    const element = document.getElementById('label--portrait'); // El contenido que deseas convertir a PDF
    const loading = document.getElementById('label-loader'); // El contenido que deseas convertir a PDF

    loading.style.display = 'block';

    if (element) {
      // Guardar el estilo original
      const originalDisplay = element.style.display;

      // Mostrar el elemento temporalmente
      element.style.display = 'block';
      loading.style.display = 'none';

      html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        element.style.display = originalDisplay;

        // Dimensiones del PDF en milímetros
        const pdfWidth = this.printerWidth || 57;
        const pdfHeight = pdfWidth * 2.56;

        const pdf = new jsPDF({
          orientation: 'portrait', // o 'landscape'
          unit: 'mm', // milímetros
          format: [pdfWidth, pdfHeight], // tamaño del PDF
        });

        // Añade la imagen al PDF
        const imgProps = pdf.getImageProperties(imgData);
        const pdfImgWidth = pdfWidth;
        const pdfImgHeight = (imgProps.height * pdfImgWidth) / imgProps.width;

        pdf.addImage(imgData, 'PNG', 0, 0, pdfImgWidth, pdfImgHeight);

        // Genera el blob del PDF
        const pdfBlob = pdf.output('blob');

        // Crea una URL para el blob
        const url = URL.createObjectURL(pdfBlob);
        window.open(url);
      });
    }
  }

  getPrinterSettingsWitdh() {
    this.printerWidth = localStorage.getItem(this.printerSettingsWitdh)
      ? Number(localStorage.getItem(this.printerSettingsWitdh))
      : null;
  }
}
