<app-navbar *ngIf="canNavBar ==true"></app-navbar>
<div class="row p-5">
  <div class="d-flex align-items-center">
    <nav *ngIf="canNavBar ==true"
      style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
      aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="../" (click)="goBack()">Back</a></li>
        <li class="breadcrumb-item active" aria-current="page">Edit part</li>
      </ol>
    </nav>
  </div>
  <div class="d-flex justify-content-between flex-row">
    <div class="piece-title">
      <h1>Edit Part </h1>
      <div class="badge-piece-status">
        <h3>
          <ng-container [ngSwitch]="producto.pieEstado">
            <ng-container *ngSwitchCase="1">
              <span class="badge rounded-pill bg-secondary">{{producto.estadoString}}</span>
            </ng-container>
            <ng-container *ngSwitchCase="2">
              <span class="badge rounded-pill bg-info">{{producto.estadoString}}</span>
            </ng-container>
            <ng-container *ngSwitchCase="3">
              <span class="badge rounded-pill bg-success">{{producto.estadoString}}</span>
            </ng-container>
            <ng-container *ngSwitchCase="4">
              <span class="badge rounded-pill bg-warning">{{producto.estadoString}}</span>
            </ng-container>
            <ng-container *ngSwitchCase="5">
              <span class="badge rounded-pill bg-danger">{{producto.estadoString}}</span>
            </ng-container>
          </ng-container>
        </h3>
      </div>
    </div>
    <img style="width: 50px; margin-top: -40px; opacity: 0.7;" src="/assets/img/newproducto.gif">
  </div>

  <hr>
  <form [formGroup]="myForm" class="row g-3" *ngIf="!loadingup;">
    <div class="col-md-12" *ngIf="isAdmin && producto.carId > 0">
      <h4>Main Car:</h4>
      <hr>
      <div class="d-flex flex-row">
        <img class="rounded" height="35" width="35" style="cursor: pointer;" [src]="producto.carFoto" alt=""
          (click)="verImagen(producto.carFoto)" />
        <input type="text" class="form-control ms-2" value="{{producto.mainCarString}}" disabled />
        <a class="btn btn-success mx-2"><i class="fa fa-pencil-square-o"></i></a>
        <a class="btn bg-info text-white" style="width:40px;" data-bs-toggle="modal" data-bs-target="#carroPopUp"><i
            class="fa fa-info"></i></a>
      </div>

    </div>


    <div class="col-md-12">
      <h4>Part Data:</h4>
      <hr>
    </div>
    <div class="col-12">
      <div class="row p-2">
        <div class="noCard col-lg-3 col-md-3 col-sm-6 col-6 p2"
          *ngFor="let img of producto.pieImagenes">
          <a (click)="verImagen(img)" class="border border-light container-image">
            <img class="fit-image" [src]="img" alt="" (click)="verImagen(img)" />
          </a>

          <div class="btnOverImg card-img-overlay text-center">
            <a (click)="removeItemFromArr(img)" class="btn btn-danger"><i class="fa fa-trash"></i></a>
          </div>
        </div>
      </div>
      <button type="button" class="btn  btn-info text-white" (click)="chooseFile()">add image <i
          class="fa fa-image"></i></button>
      <button type="button" class="ms-1 btn  btn-info text-white onlyMobil" (click)="startPhotoCapture()"> <i
          class="fa fa-camera"></i></button>
      <input resize-quality="0.7" resize-type="image/jpg" id="fileInput" name="fileInput"
        style="height:0px;overflow:hidden" type="file" multiple accept="image/png,image/jpeg"
        (change)="detectFiles($event)">
      <div class="invalid-feedback">
        <div> Please enter at least one Image.</div>
      </div>
    </div>
    <div class="col-md-6">
      <label for="inputEmail4" class="form-label">Part name *</label>
      <input type="text" class="form-control" value="{{producto.pieNombre}}" name="PieNombre" id="PieNombre" required />
      <div class="invalid-feedback">
        <div>Please enter Part name.</div>
      </div>
    </div>
    <div class="col-md-6">
      <label for="inputPassword4" class="form-label">Manufacturer Part Number</label>
      <input type="text" class="form-control" value="{{producto.pieVIN}}" name="PartNumber" id="PartNumber">
    </div>
    <div class="col-12">
      <div class="mb-3">
        <label for="exampleFormControlTextarea1" class="form-label">Part Description</label>
        <!-- <textarea class="form-control" value="{{producto.pieDescripcion}}" name="PieDescripcion" id="PieDescripcion" rows="3" required></textarea> -->
        <angular-editor id="PieDescripcion" formControlName="htmlContent" [(ngModel)]="htmlContent" [config]="config">
        </angular-editor>

        <div class="invalid-feedback">
          <div>Please enter Part Description.</div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <label for="yearfrom" class="form-label">Year From *</label>
      <ng-multiselect-dropdown name="yearfrom" formControlName="yearfrom" [(ngModel)]="this.currentYearFrom"
        [placeholder]="'Select year'" [settings]="{      
                closeDropDownOnSelection: true,
                singleSelection: true,
                idField: 'year',
                textField: 'year',
                itemsShowLimit: 1,
                allowSearchFilter: true}" [data]="years" appSelectAutoFoucs>
      </ng-multiselect-dropdown>
    </div>

    <div class="col-md-2" style="position: relative; ">
      <label for="yearto" class="form-label">Year To </label>
      <ng-multiselect-dropdown name="yearto" formControlName="yearto" [(ngModel)]="this.currentYearTo"
        [placeholder]="'Select year'" [settings]="{      
                closeDropDownOnSelection: true,
                singleSelection: true,
                idField: 'year',
                textField: 'year',
                itemsShowLimit: 1,
                allowSearchFilter: true}" [data]="years" appSelectAutoFoucs>
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-3">
      <label for="inputState" class="form-label mr-2">Make *</label>
      <div *ngIf="marListo" class="spinner-border spinner-border-sm text-primary mr-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <ng-multiselect-dropdown name="marcaf" formControlName="marcaf" [(ngModel)]="this.currentMarca"
        [placeholder]="'Select make'" [settings]="{   
                closeDropDownOnSelection: true,   
                singleSelection: true,
                idField: 'marID',
                textField: 'marDescripcion',
                itemsShowLimit: 1,
                allowSearchFilter: true}" [data]="marca" (onDeSelect)="UnSelectObject($event)"
        (onSelect)="cargarModelo($event)" appSelectAutoFoucs>
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-3">
      <label for="inputState" class="form-label">Model *</label>
      <ng-multiselect-dropdown name="modelof" formControlName="modelof" [(ngModel)]="this.currentModelo"
        [placeholder]="'Select model'" [settings]="{      
                closeDropDownOnSelection: true, 
                singleSelection: true,
                idField: 'modID',
                textField: 'modDescripcion',
                itemsShowLimit: 1,
                allowSearchFilter: true}" [data]="modelo" appSelectAutoFoucs>
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-2">
      <label for="inputState" class="form-label">Category *</label>
      <ng-multiselect-dropdown name="catf" formControlName="catf" [(ngModel)]="this.currentCategory"
        [placeholder]="'Select Category'" [settings]="{      
            closeDropDownOnSelection: true, 
            singleSelection: true,
            idField: 'catID',
            textField: 'catDescripcion',
            itemsShowLimit: 1,
            allowSearchFilter: true}" [data]="categoria" (onDeSelect)="UnSelectObject($event)"
        appSelectAutoFoucs></ng-multiselect-dropdown>

    </div>
    <!-- <div class="col-md-4" >
          <label for="inputState" class="form-label">Parts</label>
          <select [disabled]="catListo" id="catId" class="form-select" aria-label="Default select example">
            <option selected value="{{producto.categoriaString}}" >{{producto.categoriaString}} (Actual)</option>
            <option *ngFor="let part of parts" [value]="part.piePreId">
              {{part.piePreNombre}}
            </option>
          </select>   
        </div> -->
    <div class="col-md-12 mt-5">
      <h3>General information</h3>
      <hr>
    </div>
    <div class="col-md-2">
      <label for="PiePrecio" class="form-label">Price</label>
      <input type="number" value="{{producto.piePrecio}}" class="form-control" id="PiePrecio" min="1" name="PiePrecio"
        required>
      <div class="invalid-feedback">
        <div>Please enter Price.</div>
      </div>
    </div>

    <div class="col-md-2">
      <label for="pieCosto" class="form-label">Cost </label>
      <input type="number" placeholder="$0" value="{{producto.pieCosto}}" class="form-control" id="pieCosto" min="0"
        name="pieCosto" required>
      <div class="invalid-feedback">
        <div>Please enter Cost.</div>
      </div>
    </div>

    <div class="col-md-2">
      <label for="PieDisponible" class="form-label">Quantity On Hand </label>
      <input type="number" class="form-control" value="{{producto.pieDisponible}}" id="PieDisponible" min="1"
        name="PieDisponible" required>
      <div class="invalid-feedback">
        <div>Please enter Stoke.</div>
      </div>
    </div>


    <div class="col-md-2">
      <label for="pieLocation" class="form-label">Shelf/Location</label>
      <div class="input-group mb-3">
        <input placeholder="Shelf/Location" class="form-control" value="{{producto.pieLocation}}" id="pieLocation"
          name="pieLocation">
        <button class="btn btn-primary text-white" type="button" (click)="OpenCamera()"><i class="fa fa-qrcode"
            aria-hidden="true"></i></button>
      </div>
      <button hidden id="openCamera_btn" type="button" data-bs-toggle="modal" data-bs-target="#scanPopUp"></button>
    </div>

    <div class="col-md-2">
      <label for="pieMarcaFabricacion" class="form-label">Part brand</label>
      <input placeholder="Part brand" class="form-control" value="{{producto.pieMarcaFabricacion}}"
        id="pieMarcaFabricacion" name="pieMarcaFabricacion">
    </div>

    <div class="col-md-2">
      <label for="pieColor" class="form-label">Color</label>
      <input placeholder="Black" class="form-control" value="{{producto.pieColor}}" id="pieColor" name="pieColor">
    </div>

    <!-- <div class="col-md-2">
            <label for="pieCostoEnvio" class="form-label">Shipping Cost </label>
            <input placeholder="$0" class="form-control" value="{{producto.pieCostoEnvio}}" id="pieCostoEnvio" name="pieCostoEnvio">
        </div>

        <div class="col-md-2">
            <label for="piePeso" class="form-label">Weight kg</label>
            <input placeholder="0 kg" class="form-control" value="{{producto.piePeso}}" id="piePeso" name="piePeso">
        </div> -->

    <div class="col-md-2">
      <label for="pieSeñalUso" class="form-label">Grade</label>
      <select id="pieSeñalUso" class="form-select" name="pieSeñalUso" value="{{getsenalUso(producto)}}">
        <option value="A">A</option>
        <option value="B">B</option>
        <option value="C">C</option>
        <option value="D">D</option>
        <option value="E">E</option>
        <option value="F">F</option>
      </select>
      <!-- <input placeholder="A" class="form-control" value="{{getsenalUso(producto)}}" id="pieSeñalUso" name="pieSeñalUso"> -->
    </div>

    <div class="col-md-2">
      <label for="inputState" class="form-label">Condition *</label>

      <ng-multiselect-dropdown name="conditionf" [required]="'usoDescripcion'" formControlName="conditionf"
        [(ngModel)]="this.currentCondition" [placeholder]="'Select Condition'" [settings]="{      
            closeDropDownOnSelection: true, 
            singleSelection: true,
            idField: 'usoValor',
            textField: 'usoDescripcion',
            itemsShowLimit: 1,
            allowSearchFilter: true}" [data]="conditions" appSelectAutoFoucs></ng-multiselect-dropdown>
    </div>
    <div class="col-md-2" style="position: relative; ">
      <label for="inputState" class="form-label">Warranty </label>
      <ng-multiselect-dropdown name="warrantyf" formControlName="warrantyf" [(ngModel)]="this.currentWarranty"
        [placeholder]="'Select Warranty'" [settings]="{      
                closeDropDownOnSelection: true,
                singleSelection: true,
                idField: 'usoValor',
                textField: 'usoDescripcion',
                itemsShowLimit: 1,
                allowSearchFilter: true}" [data]="warranty" appSelectAutoFoucs>
      </ng-multiselect-dropdown>
    </div>
    
    <div *ngIf="isAdmin" class="col-md-12 mt-5">
      <h3>Extra information</h3>
      <hr>
    </div>

    <div *ngIf="isAdmin" class="col-md-2" style="position: relative; ">
      <label for="inputState" class="form-label">Car Type </label>
      <ng-multiselect-dropdown name="cartypef" formControlName="cartypef" [(ngModel)]="this.currentCarType"
        [placeholder]="'Select Car Type'" [settings]="{      
                closeDropDownOnSelection: true,
                singleSelection: true,
                idField: 'usoValor',
                textField: 'usoDescripcion',
                itemsShowLimit: 1,
                allowSearchFilter: true}" [data]="carTypes" appSelectAutoFoucs>
      </ng-multiselect-dropdown>
    </div>

    <div *ngIf="isAdmin" class="col-md-2" style="position: relative; ">
      <label for="inputState" class="form-label">Product Type </label>
      <ng-multiselect-dropdown name="proTypef" formControlName="proTypef" [(ngModel)]="this.currentProdType"
        [placeholder]="'Select Product Type'" [settings]="{      
                closeDropDownOnSelection: true,
                singleSelection: true,
                idField: 'usoValor',
                textField: 'usoDescripcion',
                itemsShowLimit: 1,
                allowSearchFilter: true}" [data]="prodType" appSelectAutoFoucs>
      </ng-multiselect-dropdown>
    </div>

    <div *ngIf="isAdmin" class="col-md-3" style="position: relative; ">
      <label for="inputState" class="form-label">Placement on Vehicle </label>
      <ng-multiselect-dropdown name="Placementf" formControlName="Placementf" [(ngModel)]="this.currentPlacement"
        [placeholder]="'Select Placement'" [settings]="{      
                closeDropDownOnSelection: true,
                singleSelection: false,
                idField: 'usoValor',
                textField: 'usoDescripcion',
                itemsShowLimit: 5,
                allowSearchFilter: true}" [data]="placements" appSelectAutoFoucs>
      </ng-multiselect-dropdown>
    </div>
    <div *ngIf="isAdmin" class="col-md-3" style="position: relative; ">
      <label for="inputState" class="form-label">Material</label>
      <ng-multiselect-dropdown name="materialf" formControlName="materialf" [(ngModel)]="this.currentMaterial"
        [placeholder]="'Select Material'" [settings]="{      
                closeDropDownOnSelection: true,
                singleSelection: false,
                idField: 'usoValor',
                textField: 'usoDescripcion',
                itemsShowLimit: 5,
                allowSearchFilter: true}" [data]="materials" appSelectAutoFoucs>
      </ng-multiselect-dropdown>
    </div>
    <div *ngIf="isAdmin" class="col-md-3" style="position: relative; ">
      <label for="inputState" class="form-label">Finish </label>
      <ng-multiselect-dropdown name="finishesf" formControlName="finishesf" [(ngModel)]="this.currentFinish"
        [placeholder]="'Select Finish'" [settings]="{      
                closeDropDownOnSelection: true,
                singleSelection: false,
                idField: 'usoValor',
                textField: 'usoDescripcion',
                itemsShowLimit: 5,
                allowSearchFilter: true}" [data]="finishes" appSelectAutoFoucs>
      </ng-multiselect-dropdown>
    </div>
    <div *ngIf="isAdmin" class="col-md-3" style="position: relative; ">
      <label for="inputState" class="form-label">Items Included</label>
      <ng-multiselect-dropdown name="includedf" formControlName="includedf" [(ngModel)]="this.currentIncluded"
        [placeholder]="'Select Include'" [settings]="{      
                closeDropDownOnSelection: true,
                singleSelection: false,
                idField: 'usoValor',
                textField: 'usoDescripcion',
                itemsShowLimit: 5,
                allowSearchFilter: true}" [data]="includedes">
      </ng-multiselect-dropdown>
    </div>
    <div *ngIf="isAdmin" class="col-md-3" style="position: relative; ">
      <label for="inputState" class="form-label">Features </label>
      <ng-multiselect-dropdown name="featuresf" formControlName="featuresf" [(ngModel)]="this.currentFeatures"
        [placeholder]="'Select Features'" [settings]="{      
                closeDropDownOnSelection: true,
                singleSelection: false,
                idField: 'usoValor',
                textField: 'usoDescripcion',
                itemsShowLimit: 5,
                allowSearchFilter: true}" [data]="features" appSelectAutoFoucs>
      </ng-multiselect-dropdown>
    </div>

    <div *ngIf="isAdmin && isStatusPost && producto.pieTipoIntegracion == 0" class="col-md-3"
      style="position: relative; ">
      <label for="inputState" class="form-label">Extra: </label>
      <div class="form-check form-switch btn-info">
        <input class="form-check-input" type="checkbox" value="" id="isEbay">
        <label class="form-check-label" for="flexCheckDefault">
          Post on eBay
        </label>
      </div>
    </div>

    <div class="d-flex flex-row-reverse bd-highlight p-5">
      <div class="p-2 bd-highlight" *ngIf="producto.pieEstado < 4">
        <button type="submit" class="btn btn-success btn-lg" (click)="Submit(3)">
          <i class="fa fa-paper-plane"></i> {{producto.pieEstado == 3 ? 'Re-post' : 'Post'}}
          <span *ngIf="loadingup" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
          </span>
        </button>
      </div>
      <div class="p-2 bd-highlight" *ngIf="producto.pieEstado != 3">
        <button type="submit" class="btn btn-warning btn-lg" (click)="Submit()">
         Update
          <span *ngIf="loadingup" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
          </span>
        </button>
      </div>
      <div class="p-2 bd-highlight">
        <button type="button" class="btn btn-danger btn-lg text-white" routerLink="../" (click)="goBack()">Cancel
        </button>
      </div>
    </div>
  </form>

  <div class="full-screen-overlay" *ngIf="showCaptureComponent">
    <app-photo-capture *ngIf="showCaptureComponent" (photoCaptured)="onPhotoCaptured($event)"
      (captureCancelled)="onCaptureCancelled($event)">
    </app-photo-capture>
  </div>

  <!-- Modal -->
  <div class="modal fade modal-xl" id="carroPopUp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{producto.mainCarString}}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row g-0">
            <div class="col-4 col-sm-5 col-md-3 col-lg-3 d-flex align-items-center">
              <img src="{{carro.imagen1}}" class="card-img-top ml-5 mt-2 mb-2 fit-imageCar img-fluid rounded-start"
                alt="...">
            </div>
            <div class="col-8 col-sm-8 col-md-9 col-lg-9 d-flex align-items-center">
              <div class="card-body">
                <a>
                  <p class="Nombre-text">{{carro.marcaString}} {{carro.modeloString}}
                    {{carro.carVersion}}
                    {{carro.carColor}}</p>
                </a>
                <hr>

                <div class="row g-0">
                  <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex align-items-center mx-1">
                    <div class="card-body">
                      <p class="secom-text"><small class="text-muted">VIN: {{carro.carVIN}}</small></p>
                      <p class="secom-text"><small class="text-muted">Mileage: {{carro.carKilometraje}}</small></p>
                      <p class="secom-text"><small class="text-muted">Tranmicion Info:
                          {{carro.carTranmicionInfo}}</small>
                      </p>
                    </div>

                  </div>

                  <div class="col-6 col-sm-6 col-md-5 col-lg-5 d-flex align-items-center">
                    <div class="card-body">
                      <p class="secom-text"><small class="text-muted">Model's Motor: {{carro.carModeloMotor}}</small>
                      </p>
                      <p class="secom-text"><small class="text-muted">Cilindros: {{carro.carCilindros}}</small></p>
                      <p class="secom-text"><small class="text-muted">Total Cost: {{carro.carCostoTotal
                          |currency}}</small></p>
                    </div>

                  </div>


                </div>

              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center Divloader" style="z-index: 9999;" *ngIf="loadingup;">
    <!-- <div class="spinner-border text-white" role="status" role="status">
      <span class="sr-only">Loading...</span>
    </div> -->
    <img style="width: 200px;" src="/assets/img/newproducto.gif">
  </div>

  <!-- ModalScan -->
  <div class="modal fade modal-xl" id="scanPopUp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Scan Shelf/Location</h1>
          <button type="button" class="btn-close" (click)="closeCamera()"></button>
          <button hidden id="closeCamera_btn" type="button" class="btn-close" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <div *ngIf="torchEnabled">
            <zxing-scanner [enable]="torchEnabled" [torch]="torchEnabled" [device]="deviceCurrent"
              (deviceChange)="onDeviceChange($event)" (scanSuccess)="onCodeResult($event)" [formats]="formatsEnabled"
              [tryHarder]="false" (permissionResponse)="onHasPermission($event)" (camerasFound)="onCamerasFound($event)"
              (torchCompatible)="onTorchCompatible($event)">
            </zxing-scanner>


            <button *ngIf="availableDevices.length > 1" (click)="onDeviceSelectChange()" type="button"
              class="btn btn-secondary"><i class="fa fa-retweet" aria-hidden="true"></i></button>

          </div>

        </div>
      </div>
    </div>
  </div>

  <ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Modal</h4>
      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="integrationsModalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      This is a modal.
    </div>
  </ng-template>
</div>