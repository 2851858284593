<div *ngIf="!printerWidth">
    <div class="alert alert-warning" role="alert">
        You must first configure the width of your printer in <b>settings > printer settings</b>
    </div>
</div>

<div *ngIf="printerWidth" id="label-loader" class="loading">Generating labels please wait...</div>

<div id="label-component-content" *ngIf="piece && printerWidth">
    <div id="label--portrait" class="label--container" style="display: none;">
        <div class="label-header">
            <h1 class="label-title">{{piece.pieNombre || '_'}}</h1>
            <h2 class="label-subtitle">{{piece.mainCarString || '_'}}</h2>
        </div>
        <div class="label-body">
            <div>
                <p>Mark: {{piece.marcaString}}</p>
                <p>Model: {{piece.modeloString}}</p>
                <p>Year: {{piece.pieAnoDesde}} - {{piece.pieAnoHasta}}</p>
                <p>Shelf/Location: {{piece.pieLocation}}</p>
            </div>
            <div>
                <p>Condition: {{piece.pieCondicion}}</p>
                <p>Part VIN: {{piece.pieVIN}}</p>
                <p>Grade: {{piece['pieSeñalUso']}}</p>
                <p>Color: {{piece.pieColor}}</p>
            </div>
            <div>
                <qrcode [elementType]="'img'" [qrdata]="piece.pieId.toString()" [width]="150"
                    [errorCorrectionLevel]="'M'"></qrcode>
                <p class="center">{{piece.pieId}}</p>
            </div>
        </div>
    </div>
    <div id="label--portrait-viewer">
        <div class="label--container">
            <div class="label-header">
                <h1 class="label-title">{{piece.pieNombre}}</h1>
                <h2 class="label-subtitle">{{piece.mainCarString}}</h2>
            </div>
            <div class="label-body">
                <div>
                    <p>Mark: {{piece.marcaString}}</p>
                    <p>Model: {{piece.modeloString}}</p>
                    <p>Year: {{piece.pieAnoDesde}} - {{piece.pieAnoHasta}}</p>
                    <p>Shelf/Location: {{piece.pieLocation}}</p>
                </div>
                <div>
                    <p>Condition: {{piece.pieCondicion}}</p>
                    <p>Part VIN: {{piece.pieVIN}}</p>
                    <p>Grade: {{piece['pieSeñalUso']}}</p>
                    <p>Color: {{piece.pieColor}}</p>
                </div>
                <div>
                    <qrcode [elementType]="'img'" [qrdata]="piece.pieId.toString()" [width]="150"
                        [errorCorrectionLevel]="'M'"></qrcode>
                    <p class="center">{{piece.pieId}}</p>
                </div>
            </div>
        </div>
        <button id="print-button" (click)="generateLabel()" class="btn btn-secondary">Print Label</button>
    </div>
</div>