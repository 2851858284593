import { Component, OnInit } from '@angular/core';

import { AdminService } from '../../services/admin.service';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-print-car',
  templateUrl: './print-car.component.html',
  styleUrls: ['./print-car.component.scss'],
})
export class PrintCarComponent implements OnInit {
  piece: any;
  car: any;
  private printerSettingsWitdh = 'PrinterSettingsWitdh';
  printerWidth: number;

  constructor(
    private _productosService: AdminService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getPrinterSettingsWitdh();
    this._route.params.subscribe((params) => {
      let id = params.id;
      this.getPiece(id);
    });
  }

  getPiece(id: string): void {
    this._productosService.getCarroid(id).subscribe(
      (response) => {
        this.car = response;
        if(this.printerWidth !== null) {
          setTimeout(() => {
            this.generateLabel();
          }, 1000);
        }
      },
      (error) => {
        console.log(<any>error);
      }
    );
  }

  generateLabel(): void {
    const element = document.getElementById('label--portrait'); // El contenido que deseas convertir a PDF
    const loading = document.getElementById('label-loader'); // El contenido que deseas convertir a PDF

    loading.style.display = 'block';

    if (element) {
      // Guardar el estilo original
      const originalDisplay = element.style.display;

      // Mostrar el elemento temporalmente
      element.style.display = 'block';

      html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        element.style.display = originalDisplay;
        loading.style.display = 'none';

        // Dimensiones del PDF en milímetros
        const pdfWidth = localStorage.getItem(this.printerSettingsWitdh) ? Number(localStorage.getItem(this.printerSettingsWitdh)) : 57;
        const pdfHeight = (pdfWidth * 2.56) * this.car.carPiezas.length;

        const pdf = new jsPDF({
          orientation: 'portrait', // o 'landscape'
          unit: 'mm', // milímetros
          format: [pdfWidth, pdfHeight], // tamaño del PDF
        });

        // Añade la imagen al PDF
        const imgProps = pdf.getImageProperties(imgData);
        const pdfImgWidth = pdfWidth;
        const pdfImgHeight = (imgProps.height * pdfImgWidth) / imgProps.width;

        pdf.addImage(imgData, 'PNG', 0, 0, pdfImgWidth, pdfImgHeight);

        // Genera el blob del PDF
        const pdfBlob = pdf.output('blob');

        // Crea una URL para el blob
        const url = URL.createObjectURL(pdfBlob);
        window.open(url);
      });
    }
  }

  getDynamicWidth(): string {
    return `calc(620px * ${this.car.carPiezas.length + 1})`;
  }

  getPrinterSettingsWitdh() {
    this.printerWidth = localStorage.getItem(this.printerSettingsWitdh)
      ? Number(localStorage.getItem(this.printerSettingsWitdh))
      : null;
  }
}
