import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {
  ProductosService,
  datosPieza,
  Modelo,
} from '../../services/productos.service';
import { AdminService } from '../../services/admin.service';
import { Carro } from '../../Entities/Carro';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BehaviorSubject } from 'rxjs';
import { BarcodeFormat } from '@zxing/library';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-edit-pieza',
  templateUrl: './edit-pieza.component.html',
  styleUrls: ['./edit-pieza.component.scss'],
})
export class EditPiezaComponent implements OnInit {
  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;

  integrationsModalRef?: BsModalRef;
  isAnEbayUser = false;
  canNavBar = true;
  //scam
  availableDevices: MediaDeviceInfo[] = [];
  deviceCurrent: MediaDeviceInfo;
  deviceSelected: string;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];
  hasDevices: boolean;
  hasPermission: boolean;
  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);

  carro = new Carro();

  isStatusPost = false;

  currentStatus: any[] = [];
  currentYearTo: any[] = [];
  currentYearFrom: any[] = [];
  currentMarca: any[] = [];
  currentModelo: any[] = [];
  currentCategory: any[] = [];
  currentPart: any[] = [];
  currentCondition: any[] = [];
  currentCarType: any[] = [];
  currentWarranty: any[] = [];
  currentProdType: any[] = [];
  currentPlacement: any[] = [];
  currentMaterial: any[] = [];
  currentFinish: any[] = [];
  currentIncluded: any[] = [];
  currentFeatures: any[] = [];

  conditions: any[] = [];
  carTypes: any[] = [];
  warranty: any[] = [];
  prodType: any[] = [];
  placements: any[] = [];
  materials: any[] = [];
  finishes: any[] = [];
  includedes: any[] = [];
  features: any[] = [];
  status: any[] = [];

  myForm: FormGroup;

  public producto: any;
  years: number[] = [];
  pieza: FormGroup;
  urls: string[];
  postUrl = new Array<string>();
  latitude: string;
  longitude: string;
  categoria: any;
  marca: any;
  modelo: any;
  marListo = true;
  catListo = true;
  loadingmark = true;

  isAdmin = false;
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  loadingup = false;

  datosPieza: datosPieza = {
    PieId: 0,
    CarId: 0,
    PieUsuID: 0,
    PieNombre: '',
    PieDescripcion: '',
    PiePrecio: 0,
    PieCosto: 0,
    PieFechaCreacion: new Date(),
    PieFechaActualizacion: new Date(),
    CatId: 0,
    ModelId: 0,
    EmpId: 0,
    PieEstado: 0,
    PieDisponible: 0,
    PieLocation: '',
    PieCondicion: '',
    PieVIN: '',
    PieCostoEnvio: 0,
    PieSeñalUso: '',
    PieLatitud: '',
    PieLongitud: '',
    PieMarcaFabricacion: '',
    PieGarantia: '',
    PieTipoProducto: 0,
    PieTipoProductoString: '',
    UsuId: 0,
    PieFoto: '',
    CategoriaString: '',
    ModeloString: '',
    MarcaString: '',
    MarId: 0,
    EstadoString: '',
    MainCarString: '',
    CarFoto: '',
    anoCarro: '',
    PiePeso: 0,
    PieImagenes: [],
    PieTipoIntegracion: 0,
    PieIntegracionReferencia: '',
    PieIntegracionReferencia2: '',
    PieIntegracionReferencia3: '',
    PieColor: '',
    PieMaterial: '',
    PiePlacementonVehicle: '',
    PieFinish: '',
    PieItemsIncluded: '',
    PieFeatures: '',
    PiePartType: '',
    PtypeDes: '',
    PFeaturesDes: '',
    PFinishDes: '',
    PItemsInDes: '',
    PMaterialDes: '',
    PPlacementDes: '',
  };

  model: Modelo = {
    idMarca: 0,
    marcastring: '',
  };
  htmlContent = '';
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '400px',
    sanitize: false,
    minHeight: '5rem',

    placeholder: 'Part description',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: 'Quote',
        class: 'quoteClass',
      },
      {
        name: 'Title Heading',
        class: 'titleHead',
        tag: 'h1',
      },
    ],
    toolbarHiddenButtons: [
      [],
      [
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
  };

  constructor(
    private formBuilder: FormBuilder,
    private _productosService: ProductosService,
    private _adminService: AdminService,
    private _router: Router,
    private location: Location,
    private _route: ActivatedRoute,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    var parametro = this._route.snapshot.queryParamMap.get('canNav');
    if (parametro != null) this.canNavBar = false;

    this.producto = this.datosPieza;
    this.loadingup = true;
    this.myForm = this.formBuilder.group({
      marcaf: this.currentMarca,
      modelof: this.currentModelo,
      catf: this.currentCategory,
      conditionf: this.currentCondition,
      partf: this.currentPart,
      cartypef: this.currentCarType,
      warrantyf: this.currentWarranty,
      proTypef: this.currentProdType,
      Placementf: this.currentPlacement,
      materialf: this.currentMaterial,
      finishesf: this.currentFinish,
      includedf: this.currentIncluded,
      featuresf: this.currentFeatures,
      statusf: this.currentStatus,
      yearto: this.currentYearTo,
      yearfrom: this.currentYearFrom,
      htmlContent: this.htmlContent,
    });
    if (
      localStorage.getItem('empID') == null ||
      localStorage.getItem('empID') == '0'
    ) {
      this.isAdmin = false;
    } else {
      this.isAdmin = true;
    }
    this._route.params.subscribe((params) => {
      let id = params.id;
      this.getProducto(id);
    });
    (<HTMLInputElement>document.getElementById('carroPopUp')).addEventListener(
      'shown.bs.modal',
      () => {
        this.loadingup = true;
        this._adminService.getCarroid(this.producto.carId).subscribe(
          (res) => {
            this.loadingup = false;
            this.carro = res;
          },
          (err) => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: err.error,
              showConfirmButton: true,
            });
            console.log(err);
            this.loadingup = false;
          }
        );
      }
    );
    this.getEmpresa();
  }

  goBack(): void {
    if (this.canNavBar == true) {
      this.location.back();
    } else {
      window.close();
    }
  }

  verImagen(img): void {
    Swal.fire({
      imageUrl: img,
      imageWidth: 'auto',
      imageHeight: 'auto',
      showCloseButton: true  // Muestra un botón de cierre en la esquina
    });
  }


  onPhotoCaptured(photo: any) {
    //this.urls.push(photo);
    //this.showCaptureComponent = false;
  }
  showCaptureComponent: boolean = false;

  onCaptureCancelled(photos: any) {
    photos.forEach((x) => {
      if (!this.urls.includes(x)) {
        this.urls.push(x);
      }
    });
    this.showCaptureComponent = false;
  }

  startPhotoCapture() {
    this.showCaptureComponent = true;
  }

  detectFiles(event: any) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    }
    //(this.urls);
  }

  removeItemFromArr(item, ask = false) {
    if (item.includes('http')) {
      Swal.fire({
        title: 'Are you sure? this cannot be returned',
        showDenyButton: true,
        showCancelButton: true,
        denyButtonText: 'Yes, delete it',
        showConfirmButton: false,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isDenied) {
          this.loadingup = true;
          this._adminService.deleteProIMG(item).subscribe(
            (res) => {
              this.removeLocalImg(item);
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Done',
                showConfirmButton: false,
                timer: 1500,
              });
              this.loadingup = false;
            },
            (err) => {
              console.log(err);
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: err.error,
                showConfirmButton: true,
              });
              this.loadingup = false;
            }
          );
        }
      });
    } else {
      this.removeLocalImg(item);
    }
  }

  removeLocalImg(item) {
    var arr = this.urls;
    var i = arr.indexOf(item);
    if (i !== -1) {
      arr.splice(i, 1);
    }
    //console.log(arr);
    arr = this.postUrl;
  }

  chooseFile() {
    document.getElementById('fileInput').click();
  }

  getProducto(id: any) {
    this._productosService.getMiProducto(id).subscribe(
      (response) => {
        this.producto = response;
        console.log(response);
        this.urls = this.producto.pieImagenes;
        this.htmlContent = response.pieDescripcion;
        // console.log(this.producto);
        this.getMarca();
        this.getCategoria();
        this.selectYear();
        this.getStatus();
        this.getUsosMultiples('Condition');
        this.getUsosMultiples('TipoCarros');
        this.getUsosMultiples('Warranty');
        this.getUsosMultiples('Ptype');
        this.getUsosMultiples('PPlacement');
        this.getUsosMultiples('PMaterial');
        this.getUsosMultiples('PFinish');
        this.getUsosMultiples('PItemsIn');
        this.getUsosMultiples('PFeatures');
        this.loadingup = false;
      },
      (error) => {
        this.loadingup = false;
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: error.error,
          showConfirmButton: true,
        });
        console.log(<any>error);
      }
    );
  }

  getMarca() {
    this._productosService.getMarca().subscribe(
      (res) => {
        this.loadingmark = false;
        this.marListo = false;
        this.marca = res;
        this.currentMarca = res.filter(
          (element) => element.marID == this.producto.marcaID
        );
        this.cargarModelo(this.currentMarca[0]);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getCategoria() {
    this._productosService.getCategoria().subscribe(
      (res) => {
        this.catListo = false;
        this.categoria = res;
        this.currentCategory = res.filter(
          (element) => element.catID == this.producto.catId
        );
        //this.cargarPart(this.currentCategory[0])
      },
      (err) => {
        console.log(err);
      }
    );
  }

  cargarModelo(item: any) {
    this.model.idMarca = item.marID;
    this.currentModelo = [];
    this._productosService.getModelo(this.model).subscribe(
      (res) => {
        this.modelo = res;
        //console.log(this.modelo);
        this.currentModelo = res.filter(
          (element) => element.modID == this.producto.modelId
        );
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getUsosMultiples(grupo: string): any {
    this._adminService.getUsosMultiples(grupo).subscribe(
      (res: any) => {
        if (grupo == 'Condition') {
          this.conditions = res;
          this.currentCondition = res.filter(
            (element) => element.usoDescripcion == this.producto.pieCondicion
          );
        } else if (grupo == 'TipoCarros') {
          this.carTypes = res;
          this.currentCarType = res.filter(
            (element) => element.usoValor == this.producto.pieTipoCarro
          );
        } else if (grupo == 'Warranty') {
          this.warranty = res;
          this.currentWarranty = res.filter(
            (element) => element.usoDescripcion == this.producto.pieGarantia
          );
        } else if (grupo == 'Ptype') {
          this.prodType = res;
          this.currentProdType = res.filter(
            (element) => element.usoValor == this.producto.piePartType
          );
        } else if (grupo == 'PPlacement') {
          this.placements = res;
          if (this.producto.piePlacementonVehicle != null) {
            //console.log(this.producto.piePlacementonVehicle);
            var list = [];
            this.producto.piePlacementonVehicle
              .split('~')
              .forEach((element) => {
                list.push(
                  res.filter((filtro) => filtro.usoValor == element)[0]
                );
              });
            this.currentPlacement = list;
          }
        } else if (grupo == 'PMaterial') {
          this.materials = res;
          if (this.producto.pieMaterial != null) {
            var list = [];
            this.producto.pieMaterial.split('~').forEach((element) => {
              list.push(res.filter((filtro) => filtro.usoValor == element)[0]);
            });
            this.currentMaterial = list;
          }
        } else if (grupo == 'PFinish') {
          this.finishes = res;
          if (this.producto.pieFinish != null) {
            var list = [];
            this.producto.pieFinish.split('~').forEach((element) => {
              list.push(res.filter((filtro) => filtro.usoValor == element)[0]);
            });
            this.currentFinish = list;
          }
        } else if (grupo == 'PItemsIn') {
          this.includedes = res;
          if (this.producto.pieItemsIncluded != null) {
            var list = [];
            this.producto.pieItemsIncluded.split('~').forEach((element) => {
              list.push(res.filter((filtro) => filtro.usoValor == element)[0]);
            });
            this.currentIncluded = list;
          }
        } else if (grupo == 'PFeatures') {
          this.features = res;
          if (this.producto.pieFeatures != null) {
            var list = [];
            this.producto.pieFeatures.split('~').forEach((element) => {
              list.push(res.filter((filtro) => filtro.usoValor == element)[0]);
            });
            this.currentFeatures = list;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getStatus() {
    this._productosService.getStatus('Piezas').subscribe(
      (res: any) => {
        this.status = res;
        this.currentStatus = res.filter(
          (element) => element.estEstado == this.producto.pieEstado
        );
      },
      (err) => {
        console.log(err);
      }
    );
  }

  UnSelectObject(item: any) {
    if (this.currentMarca.length == 0) {
      this.currentModelo = [];
      this.modelo = null;
    }
    if (this.currentCategory.length == 0) {
    }
  }

  selectYear() {
    var fully = new Date().getFullYear();
    for (let y = fully; y >= 1970; y--) {
      this.years.push(y);
    }
    //console.log(this.producto);
    this.currentYearFrom = this.years.filter(
      (element) => element == this.producto.pieAnoDesde
    );
    this.currentYearTo = this.years.filter(
      (element) => element == this.producto.pieAnoHasta
    );
  }

  UnSelectStatus(item: any) {
    this.isStatusPost = false;
  }

  SelectStatus(item: any) {
    if (item.estDescripcion == 'Posted') {
      this.isStatusPost = true;
    } else {
      this.isStatusPost = false;
    }
    console.log(this.isStatusPost);
    console.log(this.isAdmin);
    console.log(this.producto.pieTipoIntegracion);
  }

  async Submit(status = 2) {

    const ebayModal = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success btn-confirm-publish-ebay',
        cancelButton: 'btn btn-warning btn-cancel-publish-ebay',
      },
      buttonsStyling: false,
    });

    if (status == 3) {
      await ebayModal
        .fire({
          title: 'You want to publish your piece on ebay?',
          text: 'you can do it later, if you decide not to do it yet.',
          imageUrl:
            'https://ir.ebaystatic.com/cr/v/c1/ebay-logo-1-1200x630-margin.png',
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'You want to publish your piece on ebay?',
          showCancelButton: true,
          confirmButtonText: 'Yes, post it!',
          cancelButtonText: 'No, later!',
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.datosPieza.PieTipoIntegracion = 9;
          }
        });
    }

    try {
      this.loadingup = true;
      this.datosPieza.PieLatitud = this.producto.pieLatitud;
      this.datosPieza.PieLongitud = this.producto.pieLongitud;

      var imagenes = [];
      this.urls.forEach((element) => {
        if (!element.includes('http')) {
          imagenes.push(element);
        }
      });
      this.datosPieza.PieImagenes = imagenes;
      this.datosPieza.CarId = this.producto.carId;
      this.datosPieza.PieId = this.producto.pieId;

      var nombre = String(
        (<HTMLInputElement>document.getElementById('PieNombre')).value
      );
      if (nombre == '') {
        throw new TypeError('Part Name is required');
      }

      this.datosPieza.PieNombre = nombre;
      this.datosPieza.PieVIN = String(
        (<HTMLInputElement>document.getElementById('PartNumber')).value
      );
      this.datosPieza.PieDescripcion = this.htmlContent;

      if (this.currentYearFrom.length == 0) {
        throw new TypeError('Select the year from of the part');
      } else {
        this.datosPieza.pieAnoDesde = Number(this.currentYearFrom[0]);
      }
      if (this.currentYearTo.length == 0) {
        this.datosPieza.pieAnoHasta = this.datosPieza.pieAnoDesde;
      } else {
        this.datosPieza.pieAnoHasta = this.currentYearTo[0];
      }

      if (this.currentMarca.length == 0) {
        throw new TypeError('Select mark of the part');
      } else {
        this.datosPieza.MarcaID = this.currentMarca[0].marID;
        this.datosPieza.MarId = this.currentMarca[0].marID;
      }
      if (this.currentModelo.length == 0) {
        throw new TypeError('Select model of the part');
      } else {
        this.datosPieza.ModelId = this.currentModelo[0].modID;
      }
      if (this.currentCategory.length == 0) {
        throw new TypeError('Select category of the part');
      } else {
        this.datosPieza.CatId = this.currentCategory[0].catID;
      }

      this.datosPieza.PieEstado = status;

      this.datosPieza.PiePrecio = Number(
        (<HTMLInputElement>document.getElementById('PiePrecio')).value
      );
      this.datosPieza.PieCosto = Number(
        (<HTMLInputElement>document.getElementById('pieCosto')).value
      );
      this.datosPieza.PieDisponible = Number(
        (<HTMLInputElement>document.getElementById('PieDisponible')).value
      );
      this.datosPieza.PieLocation = String(
        (<HTMLInputElement>document.getElementById('pieLocation')).value
      );
      if (this.currentCondition.length == 0) {
        throw new TypeError('Select the condition of the part');
      } else {
        this.datosPieza.PieCondicion = this.currentCondition[0].usoDescripcion;
      }
      this.datosPieza.PieColor = String(
        (<HTMLOptionElement>document.getElementById('pieColor')).value
      );

      if (this.currentCarType.length == 0) {
        this.datosPieza.PieTipoCarro = 0;
      } else {
        this.datosPieza.PieTipoCarro = this.currentCarType[0].usoValor;
      }
      this.datosPieza.PieMarcaFabricacion = String(
        (<HTMLOptionElement>document.getElementById('pieMarcaFabricacion'))
          .value
      );
      this.datosPieza.PieSeñalUso = String(
        (<HTMLOptionElement>document.getElementById('pieSeñalUso')).value
      );
      if (this.currentWarranty.length == 0) {
        this.datosPieza.PieGarantia = '';
      } else {
        this.datosPieza.PieGarantia = this.currentWarranty[0].usoDescripcion;
      }

      this.datosPieza.PiePartType = null;
      if (this.currentProdType.length == 0) {
        this.datosPieza.PiePartType = null;
      } else {
        this.currentProdType.forEach((element) => {
          if (this.datosPieza.PiePartType == null) {
            this.datosPieza.PiePartType = `${element.usoValor}`;
          } else {
            this.datosPieza.PiePartType += `~${element.usoValor}`;
          }
        });
      }

      this.datosPieza.PiePlacementonVehicle = null;
      if (this.currentPlacement.length == 0) {
        this.datosPieza.PiePlacementonVehicle = null;
      } else {
        this.currentPlacement.forEach((element) => {
          if (this.datosPieza.PiePlacementonVehicle == null) {
            this.datosPieza.PiePlacementonVehicle = `${element.usoValor}`;
          } else {
            this.datosPieza.PiePlacementonVehicle = `${this.datosPieza.PiePlacementonVehicle}~${element.usoValor}`;
          }
        });
        //console.log(this.datosPieza.PiePlacementonVehicle);
      }

      this.datosPieza.PieMaterial = null;
      if (this.currentMaterial.length == 0) {
        this.datosPieza.PieMaterial = null;
      } else {
        this.currentMaterial.forEach((element) => {
          if (this.datosPieza.PieMaterial == null) {
            this.datosPieza.PieMaterial = `${element.usoValor}`;
          } else {
            this.datosPieza.PieMaterial += `~${element.usoValor}`;
          }
        });
      }

      this.datosPieza.PieFinish = null;
      if (this.currentFinish.length == 0) {
        this.datosPieza.PieFinish = null;
      } else {
        this.currentFinish.forEach((element) => {
          if (this.datosPieza.PieFinish == null) {
            this.datosPieza.PieFinish = `${element.usoValor}`;
          } else {
            this.datosPieza.PieFinish += `~${element.usoValor}`;
          }
        });
      }

      this.datosPieza.PieItemsIncluded = null;
      if (this.currentIncluded.length == 0) {
        this.datosPieza.PieItemsIncluded = null;
      } else {
        this.currentIncluded.forEach((element) => {
          if (this.datosPieza.PieItemsIncluded == null) {
            this.datosPieza.PieItemsIncluded = `${element.usoValor}`;
          } else {
            this.datosPieza.PieItemsIncluded += `~${element.usoValor}`;
          }
        });
      }

      this.datosPieza.PieFeatures = null;
      if (this.currentFeatures.length == 0) {
        this.datosPieza.PieFeatures = null;
      } else {
        this.currentFeatures.forEach((element) => {
          if (this.datosPieza.PieFeatures == null) {
            this.datosPieza.PieFeatures = `${element.usoValor}`;
          } else {
            this.datosPieza.PieFeatures += `~${element.usoValor}`;
          }
        });
      }

      this._productosService.updatepie(this.datosPieza).subscribe(
        (res) => {
          this.loadingup = false;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Updated',
            showConfirmButton: true,
          }).then((e) => {
            if (this.canNavBar == true) {
              if (this.isAdmin) {
                this._router.navigateByUrl('/Dashboard/Parts');
              } else {
                this._router.navigateByUrl('/profile');
              }
            } else {
              window.close();
            }
          });
        },
        (err) => {
          console.error(err);
          this.loadingup = false;
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: err.error,
            showConfirmButton: true,
          });
        }
      );
    } catch (error) {
      console.error(error);
      this.loadingup = false;
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error.message,
        showConfirmButton: true,
      });
    }
  }

  getsenalUso(producto: any): any {
    return producto['pieSeñalUso'];
  }

  OpenCamera() {
    this.torchEnabled = true;
    (<HTMLAnchorElement>document.getElementById('openCamera_btn')).click();
    console.log('open');
  }

  closeCamera() {
    this.torchEnabled = false;
    (<HTMLAnchorElement>document.getElementById('closeCamera_btn')).click();
    console.log('close');
  }

  onCamerasFound(devices: any): void {
    console.log(devices);
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);

    if (this.hasDevices == false) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Unsupported device',
        showConfirmButton: true,
      }).then(() => {
        this.closeCamera();
      });
    }
  }

  onCodeResult(resultString: any) {
    this.producto.pieLocation = resultString;
    this.closeCamera();
  }

  onDeviceSelectChange() {
    if (this.availableDevices.length > 1) {
      const device = this.availableDevices.filter(
        (x) => x.deviceId != this.deviceCurrent.deviceId
      )[0];
      this.deviceCurrent = device || undefined;
    }
  }

  onDeviceChange(device: any) {
    const selectedStr = device?.deviceId || '';
    if (this.deviceSelected === selectedStr) {
      return;
    }
    this.deviceSelected = selectedStr;
    this.deviceCurrent = device || undefined;
  }

  onHasPermission(has: any) {
    this.hasPermission = has;
    console.log(has);
    if (has == false) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'We do not have permission to open the camera',
        showConfirmButton: true,
      }).then(() => {
        this.closeCamera();
      });
    }
  }

  onTorchCompatible(isCompatible: any): void {
    this.torchAvailable$.next(isCompatible || false);
    console.log('compati' + isCompatible);
  }

  openIntegrationsModal(template: TemplateRef<void>) {
    this.integrationsModalRef = this.modalService.show(template);
  }

  getEmpresa() {
    this._adminService.getEmpresa().subscribe(
      (res) => {
        if (!res.eBaytoken || !res.eBayUser || !res.eBaytokenExpiracion) {
          this.isAnEbayUser = false;
        } else {
          this.isAnEbayUser = true;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
