<app-navbar></app-navbar>
<app-buscar></app-buscar>

<div class="row mainContainer">
  <div class="text-left my-2 ms-2 text-primary">
    <h3>Recently added parts: </h3>
  </div>
  <div class="row">
    <div class="d-flex justify-content-center" *ngIf="cargar; else elseBlock">
      <!-- <div class="spinner-border text-primary  m-5" role="status"  role="status">
                <span class="sr-only">Loading...</span>
              </div>   -->
      <div class="row">
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 portfolio-item my-1 rounded-lg">
          <app-ph class="p-3">
            (tipo)=''home''
          </app-ph>
        </div>

        <div class="col-6 col-sm-6 col-md-4 col-lg-3 portfolio-item my-1 rounded-lg">
          <app-ph class="p-3">
            (tipo)="showDateto($event)"
          </app-ph>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 portfolio-item my-1 rounded-lg">
          <app-ph class="p-3">
            [tipo]="home"
          </app-ph>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 portfolio-item my-1 rounded-lg">
          <app-ph class="p-3">
            [tipo]="home"
          </app-ph>
        </div>
      </div>

    </div>
    <ng-template #elseBlock>
      <div
        *ngFor="let producto of productos | paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count  }; let i = index "
        class="col-6 col-sm-6 col-md-4 col-lg-3 portfolio-item my-3 rounded-lg">
        <div class="card pieza rounded-3">
          <a [routerLink]="['/producto', producto.pieId]">
            <img style="background-image: url(../../../assets/img/VP_GRIS.png);"
              class="card-img-top fit-image img-fluid" src="{{producto.imagen1}}">
            <div class="card-img-overlay m-2 rounded-3">
              <h5><span class="badge bg-primary">{{producto.pieTipoProductoString}}</span></h5>
            </div>
          </a>
          <div class="piezaTextos d-inline-block">
            <p class="Nombre-text">{{producto.pieNombre}}</p>
            <p class="Precio-text">{{producto.piePrecio | currency}}</p>
          </div>
        </div>

      </div>
    </ng-template>
  </div>
  <style>
    .my-pagination /deep/ .ngx-pagination .current {
      background: #008c44;
    }
  </style>
  <div class="col-12 mt-3 d-flex justify-content-center">
    <pagination-controls class="my-pagination" previousLabel="Prev" nextLabel="Next" [responsive]="true"
      (pageChange)="handlePageChange($event)">
    </pagination-controls>
  </div>
</div>
<app-footer></app-footer>