<app-navbar></app-navbar>

<div class="row p-2">
    <div class="d-flex align-items-center">
        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/Dashboard/Cars">Cars</a></li>
                <li class="breadcrumb-item active" aria-current="page">Add Car</li>
            </ol>
        </nav>
    </div>
    <h1 class="text-primary" joyrideStep="step-1" [title]="steps['step-1'].title" [text]="steps['step-1'].text">
        New Car
    </h1>
    <hr>
    <div style="position:relative;">
        <form [formGroup]="carro" class="row g-3">
            <div class="col-md-12 ">
                <h3 class="text-primary">General information</h3>
                <hr>
            </div>
            <div class="col-12">
                <div class="row p-2">
                    <div class="noCard col-6 col-sm-6 col-md-3 col-lg-3 p-2" *ngFor="let img of urls">
                        <a (click)="verImagen(img)" class="border border-light container-image">
                            <img class="fit-image" [src]="img" alt="" (click)="verImagen(img)">
                        </a>

                        <div class="btnOverImg card-img-overlay text-cend">
                            <a (click)="removeItemFromArr(img)" class="btn btn-danger"><i class="fa fa-trash"></i></a>
                        </div>
                    </div>
                </div>
                <h3>Car Images:</h3>

                <button joyrideStep="step-2" [title]="steps['step-2'].title" [text]="steps['step-2'].text" type="button"
                    class="btn  btn-info text-white" (click)="chooseFile()">choose file <i
                        class="fa fa-image"></i></button>
                <button type="button" class="ms-1 btn  btn-info text-white onlyMobil" (click)="startPhotoCapture()"> <i
                        class="fa fa-camera"></i></button>

                <input resize-quality="0.7" resize-type="image/jpg" id="fileInput" name="fileInput"
                    style="height:0px;overflow:hidden" type="file" multiple accept="image/png,image/jpeg"
                    (change)="detectFiles($event)">
                <div class="invalid-feedback">
                    <div> Please enter at least one Image.</div>
                </div>
            </div>
            <div joyrideStep="step-3" [title]="steps['step-3'].title" [text]="steps['step-3'].text">
                <label for="inputEmail4" class="form-label mt-4">VIN *</label>
                <div class="col-md-8 d-flex" style="height: 40px;">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" formControlName="Vin" id="Vin"
                            [ngClass]="{ 'is-invalid': submitted && f.Vin.errors }" />
                        <button class="btn btn-outline-secondary" type="button" (click)="buscarVin()"
                            id="button-addon2">Seach</button>
                        <div *ngIf="submitted && f.Vin.errors" class="invalid-feedback">
                            <div>Please enter Vin.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
            </div>
            <div class="col-md-2" style="position: none; ">
                <label for="inputState" class="form-label mr-2">Status</label>
                <select class="form-select" id="status" aria-label="Default select example">
                    <option value="1" selected>Active</option>
                    <option value="2">Inactive</option>
                    <!-- <option value="3">published</option> -->
                </select>
            </div>
            <div class="col-md-2" style="position: relative; " joyrideStep="step-4" [title]="steps['step-4'].title"
                [text]="steps['step-4'].text">
                <label for="inputState" class="form-label">Year *</label>
                <ng-multiselect-dropdown appSelectAutoFoucs name="year" formControlName="year"
                    [(ngModel)]="this.currentYear" [placeholder]="'Select year'" [settings]="{      
                    closeDropDownOnSelection: true,
                    singleSelection: true,
                    idField: 'year',
                    textField: 'year',
                    itemsShowLimit: 1,
                    allowSearchFilter: true}" [data]="years">
                </ng-multiselect-dropdown>
            </div>
            <div class="col-md-2" style="position: relative; " joyrideStep="step-5" [title]="steps['step-5'].title"
                [text]="steps['step-5'].text">
                <label for="inputState" class="form-label mr-2">Make *</label>
                <ng-multiselect-dropdown appSelectAutoFoucs [disabled]="marListo" name="marcaf" formControlName="marcaf"
                    [(ngModel)]="this.currentMarca" [placeholder]="'Select make'" [settings]="{   
                    closeDropDownOnSelection: true,   
                    singleSelection: true,
                    idField: 'marID',
                    textField: 'marDescripcion',
                    itemsShowLimit: 1,
                    allowSearchFilter: true}" [data]="marca" (onDeSelect)="UnSelectObject($event)"
                    (onSelect)="cargarModelo($event)">
                </ng-multiselect-dropdown>
            </div>
            <div class="col-md-2 " style="position: relative; " joyrideStep="step-6" [title]="steps['step-6'].title"
                [text]="steps['step-6'].text">
                <label for="inputState" class="form-label">Model *</label>
                <ng-multiselect-dropdown appSelectAutoFoucs name="modelof" formControlName="modelof"
                    [(ngModel)]="this.currentModelo" [placeholder]="'Select model'" [settings]="{      
                    closeDropDownOnSelection: true, 
                    singleSelection: true,
                    idField: 'modID',
                    textField: 'modDescripcion',
                    itemsShowLimit: 1,
                    allowSearchFilter: true}" [data]="modelo">
                </ng-multiselect-dropdown>
            </div>
            <div class="col-md-2" style="position: relative; " joyrideStep="step-7" [title]="steps['step-7'].title"
                [text]="steps['step-7'].text">
                <label for="inputState" class="form-label">Type *</label>
                <ng-multiselect-dropdown appSelectAutoFoucs name="tipo" formControlName="cartipof"
                    [(ngModel)]="this.currentType" [placeholder]="'Select type'" [settings]="{      
                    closeDropDownOnSelection: true,
                    singleSelection: true,
                    idField: 'usoValor',
                    textField: 'usoDescripcion',
                    itemsShowLimit: 1,
                    allowSearchFilter: true}" [data]="cartypes">
                </ng-multiselect-dropdown>
            </div>
            <div class="col-md-2">
                <label for="inputEmail4" class="form-label">Car Version</label>
                <input placeholder="Car Version" id="carversion" type="text" class="form-control">
            </div>
            <div class="col-md-2" joyrideStep="step-8" [title]="steps['step-8'].title" [text]="steps['step-8'].text">
                <label for="color" class="form-label">Color *</label>
                <input #Color placeholder="Color" formControlName="Color" id="Color" type="text" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.Color.errors }">
                <div *ngIf="submitted && f.Costo.errors" class="invalid-feedback">
                    <div>Please enter color.</div>
                </div>
            </div>
            <div class="col-md-2">
                <label for="ColorCode" class="form-label">Color Code</label>
                <input #ColorCode placeholder="Car Color Code" formControlName="ColorCode" id="ColorCode" type="text"
                    class="form-control">
            </div>
            <div class="col-md-2" joyrideStep="step-9" [title]="steps['step-9'].title" [text]="steps['step-9'].text">
                <label for="inputEmail4" class="form-label">Mileage *</label>
                <input placeholder="Mileage" formControlName="Millage" id="Millage" type="number" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.Millage.errors }">
                <div *ngIf="submitted && f.Millage.errors" class="invalid-feedback">
                    <div>Please enter mileage.</div>
                </div>
            </div>
            <div class="col-md-2" joyrideStep="step-10" [title]="steps['step-10'].title" [text]="steps['step-10'].text">
                <label for="inputEmail4" class="form-label">Cost *</label>
                <input placeholder="$0" formControlName="Costo" #idCost id="Costo" type="number" class="form-control"
                    (change)="calcularCost()" [ngClass]="{ 'is-invalid': submitted && f.Costo.errors }">
                <div *ngIf="submitted && f.Costo.errors" class="invalid-feedback">
                    <div>Please enter cost.</div>
                </div>
            </div>
            <div class="col-md-2">
                <label for="inputEmail4" class="form-label">Tow Cost </label>
                <input placeholder="$0" id="TowCosto" type="number" (change)="calcularCost()" class="form-control">
            </div>

            <div class="col-md-2">
                <label for="inputEmail4" class="form-label">Dismantle Cost</label>
                <input placeholder="$0" id="DismantleCosto" type="number" (change)="calcularCost()"
                    class="form-control">
            </div>

            <div class="col-md-2">
                <label for="inputEmail4" class="form-label">Miscellaneous Cost</label>
                <input placeholder="$0" id="MiscellaneousCosto" type="number" (change)="calcularCost()"
                    class="form-control">
            </div>

            <div class="col-md-2">
                <label for="inputEmail4" class="form-label">Transmission info.</label>
                <input placeholder="Transmission info." id="Transmissioninfo" type="text" class="form-control">
            </div>
            <div class="col-md-2">
                <label for="inputEmail4" class="form-label">Engine Model</label>
                <input placeholder="Engine Model" id="EngineModel" type="text" class="form-control">
            </div>
            <div class="col-md-2">
                <label for="inputEmail4" class="form-label">Engine cylinders</label>
                <input placeholder="Engine cylinders" type="number" id="Enginecylinders" class="form-control">
            </div>

            <div class="col-md-12 mt-5">
                <div id="header-addpart">
                    <h3 joyrideStep="step-11" [title]="steps['step-11'].title" [text]="steps['step-11'].text">Parts
                        Include
                    </h3>
                    <button class="btn btn-success btn-lg" (click)="openAddCarModal(templateAddCardModal)">Add Parts</button>
                </div>

                <hr>
                <div class="row">
                    <p class="fs-3"><strong>Car Cost Total: {{costo | currency}}</strong> | Part Price: {{PartPrice |
                        currency}}</p>
                </div>
            </div>
          
            <div class="d-flex flex-row-reverse bd-highlight p--0">
                <div class="p-2 bd-highlight">
                    <button joyrideStep="step-14" [title]="steps['step-14'].title" [text]="steps['step-14'].text"
                        type="submit" (click)="Submit()" class="btn btn-primary btn-lg text-white">
                        <i class="fa fa-floppy-o"></i> Save Car</button>
                </div>
                <div class="p-2 bd-highlight">
                    <button class="btn btn-danger btn-lg text-white" routerLink="/Dashboard/Cars">
                        <i class="fa fa-times"></i> Cancel</button>
                </div>

            </div>
        </form>

        <div class="full-screen-overlay" *ngIf="showCaptureComponent">
            <app-photo-capture *ngIf="showCaptureComponent" (photoCaptured)="onPhotoCaptured($event)"
                (captureCancelled)="onCaptureCancelled($event)">
            </app-photo-capture>
        </div>


        <div class="d-flex justify-content-center Divloader" *ngIf="submitted;">
            <div class="spinner-border text-white  m-3" role="status" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>


    <ng-template #templateAddCardModal>
        <div class="modal-header">
            <h4 class="modal-title pull-left">Add Part</h4>
            <button type="button" class="btn-close pull-right" aria-label="Close" (click)="addCarModal?.hide()">
                <span aria-hidden="true"></span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-md-12">
                <label for="inputEmail4" class="form-label">Category</label>

                <ng-multiselect-dropdown appSelectAutoFoucs name="catf" formControlName="catf"
                    [(ngModel)]="this.currentCategory" [placeholder]="'Select Category'" [settings]="{      
            closeDropDownOnSelection: true, 
            singleSelection: true,
            idField: 'catID',
            textField: 'catDescripcion',
            itemsShowLimit: 1,
            allowSearchFilter: true}" [data]="categoria" (onDeSelect)="UnSelectObject($event)"
                    (onSelect)="cargarPart($event)"></ng-multiselect-dropdown>
            </div>
            <div class="col-md-12">
                <label for="inputEmail4" class="form-label">Parts</label>

                <ng-multiselect-dropdown appSelectAutoFoucs name="partf" formControlName="partf"
                    [(ngModel)]="this.currentPart" [placeholder]="'Select Part'" [settings]="{      
                    closeDropDownOnSelection: true, 
                    singleSelection: false,
                    idField: 'piePreId',
                    textField: 'piePreNombre',
                    itemsShowLimit: 0,
                    allowSearchFilter: true}" [data]="parts" (onSelect)="partSelect($event)"
                    (onDeSelect)="partDeselect($event)" (onSelectAll)="partSelectAll($event)">
                </ng-multiselect-dropdown>
            </div>

            <div class="row">
                <div class="col-md-12 mb-2 mt-3" *ngIf="selectedItems.length >= 1">
                    <button id="deleteAllPartsButton" type="submit" class="btn btn-danger btn-sm text-white"
                        (click)="onUnSelectAll()">
                        Delete all parts added <i class="fa fa-trash"></i></button>
                </div>
                <div id="added-parts-list">
                    <div *ngFor="let part of selectedItems" class="col-12 portfolio-item my-1 rounded-lg">

                        <div class="d-flex justify-content-between bg-light align-items-center">
                            <th scope="row">{{part.piePreId}}</th>
                            <td>{{part.piePreNombre}}</td>
                            <td><button (click)="partDeselect(part)" class="btn btn-danger btn-sm text-white">
                                    <i class="fa fa-times"></i> Delete</button></td>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="addCarModal?.hide()">Close</button>
        </div>
    </ng-template>