<div class="overlay">
    <div class="top-bar">
        <button (click)="cancel()" class="switch-button">Cencel</button>
        <button (click)="cotherPhoto()" class="cancel-button">Take Other</button>
    </div>
    <angular-cropper #cropper [imageUrl]="ImagenCrop" [cropperOptions]="cropperOptions" (ready)="onCropperReady()" class="video-stream"></angular-cropper>

    <div class="bottom-bar">
        <div class="button-container">
            <button (click)="cropPhoto()" class="capture-button"><i class="fa fa-check" aria-hidden="true"></i></button>
        </div>
    </div>
</div>