<app-navbar></app-navbar>
<div class="row p-5">
  <div class="d-flex align-items-center">
    <nav
      style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
      aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="../" (click)="goBack()">Parts</a></li>
        <li class="breadcrumb-item active" aria-current="page">Add Part</li>
      </ol>
    </nav>
  </div>

  <div class="d-flex justify-content-between flex-row">
    <div class="piece-title">
      <h1>New Part</h1>
      <div class="badge-piece-status">
        <h3>
          <span class="badge rounded-pill bg-secondary">Not Posted</span>
        </h3>
      </div>
    </div>
    <img style="width: 50px; margin-top: -40px; opacity: 0.7;" src="/assets/img/newproducto.gif">
  </div>
  <hr>
  <form [formGroup]="pieza" class="row g-3">

    <div class="col-md-12" *ngIf="isAdmin">
      <h4>Main Car:</h4>
      <hr>
      <div class="d-flex flex-row">
        <img class="rounded" height="35" width="35" style="cursor: pointer;" [src]="Currentcarro.imagen1" alt=""
          (click)="verImagen(Currentcarro.imagen1)" />
        <input type="text" class="form-control ms-2"
          value="{{Currentcarro.carId}}-{{Currentcarro.marcaString}} {{Currentcarro.modeloString}}-{{Currentcarro.carVIN}}"
          disabled />
        <a class="btn btn-success mx-2" data-bs-toggle="modal" data-bs-target="#carroPopUp"><i
            class="fa fa-pencil-square-o"></i></a>
        <a class="btn bg-danger text-white" style="width:40px;" (click)="limpiarCar()">X</a>
      </div>

    </div>

    <div class="col-md-12">
      <h3>Part Data</h3>
      <hr>
    </div>
    <div class="col-12">
      <div class="row p-2">
        <div class="noCard col-6 col-sm-6 col-md-3 col-lg-3 p-2" *ngFor="let img of urls">
          <a (click)="verImagen(img)" class="border border-light container-image">
            <img class="fit-image" [src]="img" alt="" (click)="verImagen(img)" />
          </a>

          <div class="btnOverImg card-img-overlay text-cend">
            <a (click)="removeItemFromArr(img)" class="btn btn-danger"><i class="fa fa-trash"></i></a>
          </div>
        </div>
      </div>
      <button type="button" class="btn  btn-info text-white" (click)="chooseFile()">add image <i
          class="fa fa-image"></i></button>
      <button type="button" class="ms-1 btn  btn-info text-white onlyMobil" (click)="startPhotoCapture()"> <i
            class="fa fa-camera"></i></button>

      <input resize-quality="0.7" resize-type="image/jpg" id="fileInput" name="fileInput"
        style="height:0px;overflow:hidden" type="file" multiple accept="image/png,image/jpeg"
        (change)="detectFiles($event)">
      <div class="invalid-feedback">
        <div> Please enter at least one Image.</div>
      </div>
    </div>
    <div class="col-md-6">
      <label for="inputEmail4" class="form-label">Part name *</label>
      <input type="text" class="form-control" name="PieNombre" id="PieNombre" required />
      <div class="invalid-feedback">
        <div>Please enter Part name.</div>
      </div>
    </div>
    <div class="col-md-6">
      <label for="inputPassword4" class="form-label">Manufacturer Part Number</label>
      <input type="text" class="form-control" name="PartNumber" id="PartNumber">
    </div>
    <div class="col-12">
      <div class="mb-3">
        <label for="exampleFormControlTextarea1" class="form-label">Part Description</label>
        <!-- <textarea class="form-control" value="{{producto.pieDescripcion}}" name="PieDescripcion" id="PieDescripcion" rows="3" required></textarea> -->
        <angular-editor id="PieDescripcion" formControlName="htmlContent" [(ngModel)]="htmlContent" [config]="config">
        </angular-editor>

        <div class="invalid-feedback">
          <div>Please enter Part Description.</div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <label for="yearfrom" class="form-label">Year From *</label>
      <ng-multiselect-dropdown name="yearfrom" formControlName="yearfrom" [(ngModel)]="this.currentYearFrom"
        [placeholder]="'Select year'" [settings]="{      
              closeDropDownOnSelection: true,
              singleSelection: true,
              idField: 'year',
              textField: 'year',
              itemsShowLimit: 1,
              allowSearchFilter: true}" [data]="years">
      </ng-multiselect-dropdown>
    </div>

    <div class="col-md-2" style="position: relative; ">
      <label for="yearto" class="form-label">Year To </label>
      <ng-multiselect-dropdown name="yearto" formControlName="yearto" [(ngModel)]="this.currentYearTo"
        [placeholder]="'Select year'" [settings]="{      
              closeDropDownOnSelection: true,
              singleSelection: true,
              idField: 'year',
              textField: 'year',
              itemsShowLimit: 1,
              allowSearchFilter: true}" [data]="years">
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-3">
      <label for="inputState" class="form-label mr-2">Make *</label>
      <div *ngIf="marListo" class="spinner-border spinner-border-sm text-primary mr-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <ng-multiselect-dropdown name="marcaf" formControlName="marcaf" [(ngModel)]="this.currentMarca"
        [placeholder]="'Select make'" [settings]="{   
              closeDropDownOnSelection: true,   
              singleSelection: true,
              idField: 'marID',
              textField: 'marDescripcion',
              itemsShowLimit: 1,
              allowSearchFilter: true}" [data]="marca" (onDeSelect)="UnSelectObject($event)"
        (onSelect)="cargarModelo($event, false)">
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-3">
      <label for="inputState" class="form-label">Model *</label>
      <ng-multiselect-dropdown name="modelof" formControlName="modelof" [(ngModel)]="this.currentModelo"
        [placeholder]="'Select model'" [settings]="{      
              closeDropDownOnSelection: true, 
              singleSelection: true,
              idField: 'modID',
              textField: 'modDescripcion',
              itemsShowLimit: 1,
              allowSearchFilter: true}" [data]="modelo">
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-2">
      <label for="inputState" class="form-label">Category *</label>
      <ng-multiselect-dropdown name="catf" formControlName="catf" [(ngModel)]="this.currentCategory"
        [placeholder]="'Select Category'" [settings]="{      
          closeDropDownOnSelection: true, 
          singleSelection: true,
          idField: 'catID',
          textField: 'catDescripcion',
          itemsShowLimit: 1,
          allowSearchFilter: true}" [data]="categoria" (onDeSelect)="UnSelectObject($event)"
          appSelectAutoFoucs></ng-multiselect-dropdown>

    </div>
    <!-- <div class="col-md-4" >
        <label for="inputState" class="form-label">Parts</label>
        <select [disabled]="catListo" id="catId" class="form-select" aria-label="Default select example">
          <option selected value="{{producto.categoriaString}}" >{{producto.categoriaString}} (Actual)</option>
          <option *ngFor="let part of parts" [value]="part.piePreId">
            {{part.piePreNombre}}
          </option>
        </select>   
      </div> -->
    <div class="col-md-12 mt-5">
      <h3>General information</h3>
      <hr>
    </div>
    <div class="col-md-2">
      <label for="PiePrecio" class="form-label">Price</label>
      <input type="number" for="inputEmail4" class="form-control" id="PiePrecio" min="1" name="PiePrecio" required>
      <div class="invalid-feedback">
        <div>Please enter Price.</div>
      </div>
    </div>

    <div class="col-md-2">
      <label for="pieCosto" class="form-label">Cost </label>
      <input type="number" placeholder="$0" class="form-control" id="pieCosto" min="0" name="pieCosto" required>
      <div class="invalid-feedback">
        <div>Please enter Cost.</div>
      </div>
    </div>

    <div class="col-md-2">
      <label for="PieDisponible" class="form-label">Quantity On Hand </label>
      <input type="number" class="form-control" id="PieDisponible" min="1" name="PieDisponible" required>
      <div class="invalid-feedback">
        <div>Please enter Stoke.</div>
      </div>
    </div>

    <div class="col-md-2">
      <label for="pieLocation" class="form-label">Shelf/Location</label>
      <div class="input-group mb-3">
        <input placeholder="Shelf/Location" class="form-control" id="pieLocation" name="pieLocation">
        <button class="btn btn-primary text-white" type="button" (click)="OpenCamera()" ><i class="fa fa-qrcode" aria-hidden="true"></i></button>
      </div>
      <button hidden id="openCamera_btn" type="button" data-bs-toggle="modal" data-bs-target="#scanPopUp"></button>
    </div>

    <div class="col-md-2">
      <label for="pieMarcaFabricacion" class="form-label">Part brand</label>
      <input placeholder="Part brand" class="form-control" id="pieMarcaFabricacion" name="pieMarcaFabricacion">
    </div>

    <div class="col-md-2">
      <label for="pieColor" class="form-label">Color</label>
      <input placeholder="Black" class="form-control" id="pieColor" name="pieColor">
    </div>

    <!-- <div class="col-md-2">
          <label for="pieCostoEnvio" class="form-label">Shipping Cost </label>
          <input placeholder="$0" class="form-control" value="{{producto.pieCostoEnvio}}" id="pieCostoEnvio" name="pieCostoEnvio">
      </div>

      <div class="col-md-2">
          <label for="piePeso" class="form-label">Weight kg</label>
          <input placeholder="0 kg" class="form-control" value="{{producto.piePeso}}" id="piePeso" name="piePeso">
      </div> -->

    <div class="col-md-2">
      <label for="pieSeñalUso" class="form-label">Grade</label>
      <select id="pieSeñalUso" class="form-select" name="pieSeñalUso">
        <option value="A">A</option>
        <option value="B">B</option>
        <option value="C">C</option>
        <option value="D">D</option>
        <option value="E">E</option>
        <option value="F">F</option>
      </select>
      <!-- <input placeholder="A" class="form-control" value="{{getsenalUso(producto)}}" id="pieSeñalUso" name="pieSeñalUso"> -->
    </div>

    <div class="col-md-2">
      <label for="inputState" class="form-label">Condition *</label>

      <ng-multiselect-dropdown name="conditionf" [required]="'usoDescripcion'" formControlName="conditionf"
        [(ngModel)]="this.currentCondition" [placeholder]="'Select Condition'" [settings]="{      
          closeDropDownOnSelection: true, 
          singleSelection: true,
          idField: 'usoValor',
          textField: 'usoDescripcion',
          itemsShowLimit: 1,
          allowSearchFilter: true}" [data]="conditions"
          appSelectAutoFoucs></ng-multiselect-dropdown>
    </div>
    <div class="col-md-2" style="position: relative; ">
      <label for="inputState" class="form-label">Warranty </label>
      <ng-multiselect-dropdown name="warrantyf" formControlName="warrantyf" [(ngModel)]="this.currentWarranty"
        [placeholder]="'Select Warranty'" [settings]="{      
              closeDropDownOnSelection: true,
              singleSelection: true,
              idField: 'usoValor',
              textField: 'usoDescripcion',
              itemsShowLimit: 1,
              allowSearchFilter: true}" [data]="warranty">
      </ng-multiselect-dropdown>
    </div>
    
    <div *ngIf="isAdmin" class="col-md-12 mt-5">
      <h3>Extra information</h3>
      <hr>
    </div>

    <div *ngIf="isAdmin" class="col-md-2" style="position: relative; ">
      <label for="inputState" class="form-label">Car Type </label>
      <ng-multiselect-dropdown name="cartypef" formControlName="cartypef" [(ngModel)]="this.currentCarType"
        [placeholder]="'Select Car Type'" [settings]="{      
              closeDropDownOnSelection: true,
              singleSelection: true,
              idField: 'usoValor',
              textField: 'usoDescripcion',
              itemsShowLimit: 1,
              allowSearchFilter: true}" [data]="carTypes">
      </ng-multiselect-dropdown>
    </div>

    <div *ngIf="isAdmin" class="col-md-2" style="position: relative; ">
      <label for="inputState" class="form-label">Product Type </label>
      <ng-multiselect-dropdown name="proTypef" formControlName="proTypef" [(ngModel)]="this.currentProdType"
        [placeholder]="'Select Product Type'" [settings]="{      
              closeDropDownOnSelection: true,
              singleSelection: true,
              idField: 'usoValor',
              textField: 'usoDescripcion',
              itemsShowLimit: 1,
              allowSearchFilter: true}" [data]="prodType">
      </ng-multiselect-dropdown>
    </div>

    <div *ngIf="isAdmin" class="col-md-3" style="position: relative; ">
      <label for="inputState" class="form-label">Placement on Vehicle </label>
      <ng-multiselect-dropdown name="Placementf" formControlName="Placementf" [(ngModel)]="this.currentPlacement"
        [placeholder]="'Select Placement'" [settings]="{      
              closeDropDownOnSelection: true,
              singleSelection: false,
              idField: 'usoValor',
              textField: 'usoDescripcion',
              itemsShowLimit: 5,
              allowSearchFilter: true}" [data]="placements">
      </ng-multiselect-dropdown>
    </div>
    <div *ngIf="isAdmin" class="col-md-3" style="position: relative; ">
      <label for="inputState" class="form-label">Material</label>
      <ng-multiselect-dropdown name="materialf" formControlName="materialf" [(ngModel)]="this.currentMaterial"
        [placeholder]="'Select Material'" [settings]="{      
              closeDropDownOnSelection: true,
              singleSelection: false,
              idField: 'usoValor',
              textField: 'usoDescripcion',
              itemsShowLimit: 5,
              allowSearchFilter: true}" [data]="materials">
      </ng-multiselect-dropdown>
    </div>
    <div *ngIf="isAdmin" class="col-md-3" style="position: relative; ">
      <label for="inputState" class="form-label">Finish </label>
      <ng-multiselect-dropdown name="finishesf" formControlName="finishesf" [(ngModel)]="this.currentFinish"
        [placeholder]="'Select Finish'" [settings]="{      
              closeDropDownOnSelection: true,
              singleSelection: false,
              idField: 'usoValor',
              textField: 'usoDescripcion',
              itemsShowLimit: 5,
              allowSearchFilter: true}" [data]="finishes">
      </ng-multiselect-dropdown>
    </div>
    <div *ngIf="isAdmin" class="col-md-3" style="position: relative; ">
      <label for="inputState" class="form-label">Items Included</label>
      <ng-multiselect-dropdown name="includedf" formControlName="includedf" [(ngModel)]="this.currentIncluded"
        [placeholder]="'Select Include'" [settings]="{      
              closeDropDownOnSelection: true,
              singleSelection: false,
              idField: 'usoValor',
              textField: 'usoDescripcion',
              itemsShowLimit: 5,
              allowSearchFilter: true}" [data]="includedes">
      </ng-multiselect-dropdown>
    </div>
    <div *ngIf="isAdmin" class="col-md-3" style="position: relative; ">
      <label for="inputState" class="form-label">Features </label>
      <ng-multiselect-dropdown name="featuresf" formControlName="featuresf" [(ngModel)]="this.currentFeatures"
        [placeholder]="'Select Features'" [settings]="{      
              closeDropDownOnSelection: true,
              singleSelection: false,
              idField: 'usoValor',
              textField: 'usoDescripcion',
              itemsShowLimit: 5,
              allowSearchFilter: true}" [data]="features">
      </ng-multiselect-dropdown>
    </div>

    <div *ngIf="isAdmin && isStatusPost" class="col-md-3" style="position: relative; ">
      <label for="inputState" class="form-label">Extra: </label>
      <div class="form-check form-switch btn-info">
        <input class="form-check-input" type="checkbox" value="" id="isEbay">
        <label class="form-check-label" for="flexCheckDefault">
          Post on eBay
        </label>
      </div>
    </div>


    <div class="d-flex flex-row-reverse bd-highlight p-5">
      <div *ngIf="!isStatusPost" class="p-2 bd-highlight">
        <button type="submit" class="btn btn-warning btn-lg" (click)="Submit()"> <i class="fa fa-edit"></i> | Save <span
            *ngIf="loadingup" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
      </div>
      <div *ngIf="isStatusPost" class="p-2 bd-highlight">
        <a class="btn btn-success btn-lg text-white" (click)="Submit()"> <i class="fa fa-save"></i> | Post</a>
      </div>
      <div class="p-2 bd-highlight">
        <button type="button" class="btn btn-danger btn-lg text-white"
          (click)="goBack()">Cancel</button>
      </div>
    </div>
  </form>

  <!-- Modal Buscar-->
  <div class="modal fade modal-xl" id="carroPopUp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Search</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">


          <form [formGroup]="myFormCar">
            <div class="row">

              <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item mt-3">
                <h6 class="text-primary">Year</h6>

                <ng-multiselect-dropdown name="yearCar" formControlName="yearCar" [(ngModel)]="this.currentYearCar"
                  [placeholder]="'Select year'" [settings]="{      
                    closeDropDownOnSelection: true,
                    singleSelection: true,
                    idField: 'year',
                    textField: 'year',
                    itemsShowLimit: 1,
                    allowSearchFilter: true}" [data]="years"
                    appSelectAutoFoucs></ng-multiselect-dropdown>

              </div>


              <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item mt-3 ">
                <h6 class="text-primary ">Make</h6>

                <ng-multiselect-dropdown [disabled]="marListo" name="marcaCar" formControlName="marcaCar"
                  [(ngModel)]="this.currentMarcaCar" [placeholder]="'Select make'" [settings]="{   
                  closeDropDownOnSelection: true,   
                  singleSelection: true,
                  idField: 'marID',
                  textField: 'marDescripcion',
                  itemsShowLimit: 1,
                  allowSearchFilter: true}" [data]="marca" (onDeSelect)="UnSelectObject($event)"
                  (onSelect)="cargarModelo($event, true)"
                  appSelectAutoFoucs></ng-multiselect-dropdown>

              </div>

              <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item mt-3">
                <h6 class="text-primary">Model</h6>

                <ng-multiselect-dropdown name="modeloCar" formControlName="modeloCar"
                  [(ngModel)]="this.currentModeloCar" [placeholder]="'Select model'" [settings]="{      
                  closeDropDownOnSelection: true, 
                  singleSelection: true,
                  idField: 'modID',
                  textField: 'modDescripcion',
                  itemsShowLimit: 1,
                  allowSearchFilter: true}" [data]="modeloCar"
                  appSelectAutoFoucs></ng-multiselect-dropdown>

              </div>

              <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item mt-3">
                <h6 class="text-primary">VIN</h6>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" placeholder="Vin" aria-label="Recipient's username"
                    id="vinCar" aria-describedby="button-addon2">
                  <button class="btn btn-primary text-white" (click)="buscarcarro(false)">Seach</button>
                  <button class="btn btn-danger text-white" (click)="buscarcarro(true)">x</button>
                </div>
              </div>

              <hr style="border: black; border-width: 1px;" />

            </div>
          </form>

          <div class="card mb-3" *ngFor="let carro of carros">
            <div class="d-flex flex-row justify-content-between">
              <div class="d-flex align-items-center">
                <div class="p-2">
                  <p class="mt-1">{{carro.marcaString}} {{carro.modeloString}} {{carro.carColor}}</p>
                  <hr>

                  <div class="d-flex align-items-center flex-row">
                    <img class="me-2" src="{{carro.imagen1}}" style="width: 100px; height: 100px;">

                    <div class="mx-1">
                      <p>VIN: {{carro.carVIN}}</p>
                      <p>Mileage: {{carro.carKilometraje}}</p>
                      <p>Tranmicion Info: {{carro.carTranmicionInfo}}</p>
                    </div>

                    <div class="">
                      <p>Model's Motor: {{carro.carModeloMotor}}</p>
                      <p>Cilindros: {{carro.carCilindros}}</p>
                      <p>Total Cost: {{carro.carCostoTotal |currency}}</p>
                    </div>

                  </div>


                </div>

              </div>

              <button class="btn btn-success" (click)="SelectCarro(carro)"> Select</button>

            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button id="cloceSearc_btn" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>


  <div class="full-screen-overlay" *ngIf="showCaptureComponent" >
    <app-photo-capture 
    *ngIf="showCaptureComponent" 
    (photoCaptured)="onPhotoCaptured($event)" 
    (captureCancelled)="onCaptureCancelled($event)">
  </app-photo-capture>
</div>

  <div class="d-flex justify-content-center Divloader" style="z-index: 9999;" *ngIf="loadingup;">
    <div class="spinner-border text-white  m-3" role="status" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>



      <!-- ModalScan -->
      <div class="modal fade modal-xl" id="scanPopUp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Scan Shelf/Location</h1>
            <button type="button" class="btn-close" (click)="closeCamera()"></button>
            <button hidden id="closeCamera_btn" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
  
          <div class="modal-body">
            <div *ngIf="torchEnabled">
              <zxing-scanner 
              [enable]="torchEnabled"
              [torch]="torchEnabled" 
              [device]="deviceCurrent" 
              (deviceChange)="onDeviceChange($event)"
              (scanSuccess)="onCodeResult($event)"
              [formats]="formatsEnabled" 
              [tryHarder]="false" 
              (permissionResponse)="onHasPermission($event)"
              (camerasFound)="onCamerasFound($event)" 
              (torchCompatible)="onTorchCompatible($event)">
              </zxing-scanner>
          
          
              <button *ngIf="availableDevices.length > 1" (click)="onDeviceSelectChange()" type="button" class="btn btn-secondary"><i class="fa fa-retweet" aria-hidden="true"></i></button>
          
            </div>
  
          </div>
        </div>
      </div>
    </div>

</div>