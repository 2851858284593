import { Component, OnInit, TemplateRef } from '@angular/core';
import { ProductosService, Modelo } from '../../../services/productos.service';
import { AdminService } from '../../../services/admin.service';
import { Carro } from '../../../Entities/Carro';
import { piezasBuscar } from '../../../Entities/Pieza';
import { LocationService } from '../../../services/location.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-editar-carro',
  templateUrl: './editar-carro.component.html',
  styleUrls: ['./editar-carro.component.scss'],
})
export class EditarCarroComponent implements OnInit {
  addCarModal?: BsModalRef;

  selectedItems = [];
  PartPrice = 0;
  currentCategory: any[] = [];
  currentPart: any[] = [];
  catID: any;
  currentTab = 1;

  currentYear: any[] = [];
  currentMarca: any[] = [];
  currentModelo: any[] = [];
  currentType: any[] = [];

  loading = true;
  public searchFilter: any = '';
  public query = '';
  public piezas: any[] = [];

  years: number[] = [];
  cartypes: any[] = [];
  carro: FormGroup;
  carroD: any;
  parts: any;
  urls: string[] = [];
  postUrl = new Array<string>();
  latitude: string;
  longitude: string;
  categoria: any;
  marca: any;
  modelo: any;
  marListo = true;
  catListo = true;
  loadingmark = true;
  submitted = false;
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  loadingL = false;
  loadingP = false;
  loadingDP = false;
  loadingDL = false;
  usuId: any;
  selected: any;
  results: any;
  costo = 0;
  costoperPart = 0;
  empId: any;
  usuSesion: any;

  datosCarro = new Carro();

  model: Modelo = {
    idMarca: 0,
    marcastring: '',
  };

  constructor(
    private _adminService: AdminService,
    private formBuilder: FormBuilder,
    private _productoService: ProductosService,
    private _locationService: LocationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      let id = params.id;
      this.getcarro(id);
      this.usuId = localStorage.getItem('usuId');
    });

    this.getCategoria();

    this.carro = this.formBuilder.group({
      Color: ['', Validators.required],
      Millage: ['', Validators.required],
      Costo: ['', Validators.required],
      year: this.currentYear,
      marcaf: this.currentMarca,
      modelof: this.currentModelo,
      cartipof: this.currentType,
      catf: this.currentCategory,
      partf: this.currentPart,
    });
  }

  getcarro(id: any) {
    this._adminService.getCarroid(id).subscribe(
      (response) => {
        this.carroD = response;
        this.costo = this.carroD.carCostoTotal;
        this.costoperPart =
          this.carroD.carCostoTotal / response.carPiezas.length;
        this.urls = this.carroD.carImagenes;
        this.piezas = response.carPiezas;
        console.log(this.carroD);

        this.buscar();
        this.getMarca();
        this.selectYear();
        this.getUsosMultiples();
        this.loading = false;
      },
      (error) => {
        console.log(<any>error);
        if (error.error.includes('You do not have permissions to access to ')) {
          Swal.fire({
            icon: 'error',
            title: error.error,
            showConfirmButton: true,
          }).then(() => {
            this._router.navigateByUrl('/Dashboard');
          });
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: error.error,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    );
  }

  getUsosMultiples() {
    this._adminService.getUsosMultiples('TipoCarros').subscribe(
      (response) => {
        this.cartypes = response;
        console.log(response);
        this.currentType = this.cartypes.filter(
          (element) => element.usoValor == this.carroD.carTipo
        );
      },
      (error) => {
        console.log(<any>error);
      }
    );
  }

  get f() {
    return this.carro.controls;
  }

  onPhotoCaptured(photo: any) {
    //this.urls.push(photo);
    //this.showCaptureComponent = false;
  }
  showCaptureComponent: boolean = false;

  onCaptureCancelled(photos: any) {
    photos.forEach((x) => {
      if (!this.urls.includes(x)) {
        this.urls.push(x);
      }
    });
    this.showCaptureComponent = false;
  }

  startPhotoCapture() {
    this.showCaptureComponent = true;
  }

  detectFiles(event: any) {
    //this.urls;
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    }
    console.log(this.urls);
  }

  verImagen(img): void {
    Swal.fire({
      imageUrl: img,
      imageWidth: '100%',
      imageHeight: '100%',
    });
  }

  selectYear() {
    var fully = new Date().getFullYear();
    for (let y = fully; y >= 1970; y--) {
      this.years.push(y);
    }
    this.currentYear = this.years.filter(
      (element) => element == this.carroD.añoFabricacion
    );
  }

  removeItemFromArr(item, ask = false) {
    if (item.includes('http')) {
      Swal.fire({
        title: 'Are you sure? this cannot be returned',
        showDenyButton: true,
        showCancelButton: true,
        denyButtonText: 'Yes, delete it',
        showConfirmButton: false,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isDenied) {
          this.loading = true;
          this._adminService.deleteIMG(item).subscribe(
            (res) => {
              this.removeLocalImg(item);
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Done',
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
            },
            (err) => {
              console.log(err);
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: err.error,
                showConfirmButton: true,
              });
              this.loading = false;
            }
          );
        }
      });
    } else {
      this.removeLocalImg(item);
    }
  }

  removeLocalImg(item) {
    var arr = this.urls;
    var i = arr.indexOf(item);
    if (i !== -1) {
      arr.splice(i, 1);
    }
    console.log(arr);
    arr = this.postUrl;
  }

  chooseFile() {
    document.getElementById('fileInput').click();
  }

  getLocation() {
    console.log('aqui');
    this._locationService.getPosition().then((pos) => {
      this.latitude = pos.lat;
      this.longitude = pos.lng;
    });
  }

  getMarca() {
    this._productoService.getMarca().subscribe(
      (res) => {
        this.loadingmark = false;
        this.marListo = false;
        this.marca = res;

        this.currentMarca = res.filter(
          (element) => element.marID == this.carroD.marcaId
        );
        this.cargarModelo(this.currentMarca[0]);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  cargarModelo(item: any) {
    this.model.idMarca = item.marID;
    this.currentModelo = [];
    this._productoService.getModelo(this.model).subscribe(
      (res) => {
        this.modelo = res;
        this.currentModelo = res.filter(
          (element) => element.modID == this.carroD.modeloId
        );
        //console.log(this.modelo);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAnoFabricacion(carro: any): any {
    return carro['añoFabricacion'];
  }

  Submit() {
    this.loading = true;
    try {
      var imagenes = [];
      this.urls.forEach((element) => {
        if (!element.includes('http')) {
          imagenes.push(element);
        }
      });

      this.datosCarro.carImagenes = this.urls;

      if (this.currentYear.length == 0) {
        throw new TypeError('Years is necesary');
      } else {
        this.datosCarro.añoFabricacion = this.currentYear[0].toString();
      }

      if (this.currentMarca.length == 0) {
        throw new TypeError('Mark is necesary');
      } else {
        this.datosCarro.marcaId = this.currentMarca[0].marID;
      }

      if (this.currentModelo.length == 0) {
        throw new TypeError('Model is necesary');
      } else {
        this.datosCarro.modeloId = this.currentModelo[0].modID;
      }

      if (this.currentType.length == 0) {
        throw new TypeError('Type is necesary');
      } else {
        this.datosCarro.carTipo = this.currentType[0].usoValor;
      }

      if (
        Number((<HTMLInputElement>document.getElementById('Costo')).value) == 0
      ) {
        throw new TypeError('Cost is necesary');
      }

      if (
        Number((<HTMLInputElement>document.getElementById('Millage')).value) ==
        0
      ) {
        throw new TypeError('Millage is necesary');
      }

      if ((<HTMLInputElement>document.getElementById('Color')).value == '') {
        throw new TypeError('Color is necesary');
      }

      this.datosCarro.carVIN = (<HTMLInputElement>(
        document.getElementById('Vin')
      )).value;
      this.datosCarro.carStatus = Number(
        (<HTMLOptionElement>document.getElementById('status')).value
      );

      this.datosCarro.carVersion = (<HTMLInputElement>(
        document.getElementById('carversion')
      )).value;
      this.datosCarro.carColor = (<HTMLInputElement>(
        document.getElementById('Color')
      )).value;
      this.datosCarro.carColorCode = (<HTMLInputElement>(
        document.getElementById('ColorCode')
      )).value;
      this.datosCarro.carKilometraje = (<HTMLInputElement>(
        document.getElementById('Millage')
      )).value;
      this.datosCarro.carCosto = Number(
        (<HTMLInputElement>document.getElementById('Costo')).value
      );
      this.datosCarro.carCostoRemolque = Number(
        (<HTMLInputElement>document.getElementById('TowCosto')).value
      );
      this.datosCarro.carCostoDesmantelamiento = Number(
        (<HTMLInputElement>document.getElementById('DismantleCosto')).value
      );
      this.datosCarro.carCostoExtra = Number(
        (<HTMLInputElement>document.getElementById('MiscellaneousCosto')).value
      );
      this.datosCarro.carTranmicionInfo = (<HTMLInputElement>(
        document.getElementById('Transmissioninfo')
      )).value;
      this.datosCarro.carCilindros = (<HTMLInputElement>(
        document.getElementById('Enginecylinders')
      )).value;
      this.datosCarro.carModeloMotor = (<HTMLInputElement>(
        document.getElementById('EngineModel')
      )).value;

      this.datosCarro.defaultsPiezas = this.selectedItems;

      this.datosCarro.carId = this.carroD.carId;
      this.datosCarro.carCostoTotal = Number(
        (<HTMLInputElement>document.getElementById('Costo')).value
      );

      this._adminService.putCarros(this.datosCarro).subscribe(
        (res) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Done',
            showConfirmButton: false,
            timer: 1500,
          });
          this._router.navigateByUrl('/Dashboard/Cars');
          // window.location.reload();
        },
        (err) => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: err.error,
            showConfirmButton: true,
          });
          this.loading = false;
        }
      );
    } catch ({ name, message }) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        showConfirmButton: true,
      });
      this.loading = false;
    }
  }

  UnSelectObject(item: any) {
    console.log(this.currentMarca);
    if (this.currentMarca.length == 0) {
      this.currentModelo = [];
      this.modelo = null;
    }
  }

  filter(event: any) {
    var filtro = (<HTMLInputElement>document.getElementById('search_im')).value;
    if (filtro == '' || filtro == ' ') {
      this.piezas = this.carroD.carPiezas;
    } else {
      this.piezas = this.carroD.carPiezas.filter(
        (x) =>
          x.pieNombre.toUpperCase().includes(filtro.toUpperCase()) ||
          x.estadoString.toUpperCase().includes(filtro.toUpperCase()) ||
          x.pieId.toString().includes(filtro)
      );
    }
  }

  openEditPart(id: any) {
    window.open('/#/editpieza/' + id + '?canNav=true', '_blank');
  }

  PostTosale() {
    this.loading = true;
    this._adminService.postCarrosToPart(this.carroD.carId).subscribe(
      (response) => {
        console.log(response);
        this.openEditPart(response);
        this.loading = false;
      },
      (error) => {
        console.log(error);
        if (error.error.includes('You do not have permissions to access to ')) {
          Swal.fire({
            icon: 'error',
            title: error.error,
            showConfirmButton: true,
          }).then(() => {
            this._router.navigateByUrl('/Dashboard');
          });
          this.loading = false;
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: error.error,
            showConfirmButton: false,
            timer: 1500,
          });
          this.loading = false;
        }
      }
    );
  }

  buscar(): void {
    this.loading = true;

    var buscar = new piezasBuscar();
    buscar.cat = 15;
    buscar.maincar = this.carroD.carId;

    this._productoService.Mipiezas(buscar).subscribe(
      (res) => {
        this.loading = false;
        var botonSale = <HTMLElement>document.getElementById('postSale');

        if (res.results.length > 0) {
          var part = res.results[0];
          botonSale.textContent = 'Edit Post';
          botonSale.addEventListener('click', (event: MouseEvent) =>
            this.openEditPart(part.pieId)
          );
        } else {
          botonSale.textContent = 'Post to sale';
          botonSale.addEventListener('click', (event: MouseEvent) =>
            this.PostTosale()
          );
        }
      },
      (err) => {
        (<HTMLElement>document.getElementById('postSale')).textContent =
          'Post to sale';
        (<HTMLElement>document.getElementById('postSale')).addEventListener(
          'click',
          (event: MouseEvent) => this.PostTosale()
        );
        this.loading = false;
      }
    );
  }

  addPart() {
    window.open(`/#/addpieza?carVin=${this.carroD.carVIN}`, '_blank');
  }

  partSelect(item: any) {
    this.currentPart = [];
    item.piePreCatId = this.catID;
    item.piePreUsuSesion = this.usuSesion;
    item.piePreFecha = new Date();
    if (!this.selectedItems.some((x) => x.piePreId == item.piePreId))
      this.selectedItems.push(item);

    this.calcularCost();
  }

  partDeselect(item: any) {
    var indexBorrar = this.selectedItems.indexOf(
      this.selectedItems.find((x) => x == item)
    );
    this.selectedItems.splice(indexBorrar, 1);
    this.calcularCost();
  }

  partSelectAll(item: any) {
    this.currentPart = [];
    console.log(item);
    item.forEach((x) => {
      this.partSelect(x);
    });

    this.calcularCost();
  }

  cargarPart(item: any) {
    this.catID = item.catID;
    this.currentPart = [];
    console.log(item.catID);
    this._productoService.getPart(item.catID).subscribe(
      (res: any) => {
        this.parts = res;
        var indexBorrar = res.indexOf(res.find((x) => x.piePreCatId == 0));
        this.parts.splice(indexBorrar, 1);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  calcularCost() {
    var cos = Number(
      (<HTMLInputElement>document.getElementById('Costo')).value
    );
    var coTowCostosto = Number(
      (<HTMLInputElement>document.getElementById('TowCosto')).value
    );
    var DismantleCosto = Number(
      (<HTMLInputElement>document.getElementById('DismantleCosto')).value
    );
    var MiscellaneousCosto = Number(
      (<HTMLInputElement>document.getElementById('MiscellaneousCosto')).value
    );

    this.costo = cos + coTowCostosto + DismantleCosto + MiscellaneousCosto;
    this.PartPrice =
      this.costo / (this.selectedItems.length + this.carroD.carPiezas.length);
  }

  onUnSelectAll() {
    this.selectedItems = [];
    this.currentPart = [];
    this.calcularCost();
  }

  SelectTab(select: number) {
    this.currentTab = select;
    this.calcularCost();
  }

  getCategoria() {
    this._productoService.getCategoria().subscribe(
      (res) => {
        this.catListo = false;
        this.categoria = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  openAddCarModal(template: TemplateRef<void>) {
    this.addCarModal = this.modalService.show(template);
  }
}
