import { Component, ViewChild, ElementRef, OnInit, OnDestroy, Output,Input, EventEmitter } from '@angular/core';
import { CropperComponent } from "angular-cropperjs";

@Component({
  selector: 'app-vin-scan',
  templateUrl: './vin-scan.component.html',
  styleUrls: ['./vin-scan.component.scss']
})
export class VinScanComponent implements OnInit {
  @ViewChild(CropperComponent, { static: true })
  public cropperElement: CropperComponent;


  @ViewChild('video') video: ElementRef<HTMLVideoElement>;
  @Output() photoCroped= new EventEmitter<string>();
  @Output() CropedCancelled = new EventEmitter();
  @Output() CropedOther = new EventEmitter<any>();
  

  @Input() ImagenCrop: string = "";;

  constructor() {}

  ngOnInit(): void {
  }
  cropPhoto() {
    const photoDataUrl = this.cropperElement.cropper.getCroppedCanvas().toDataURL()
    this.photoCroped.emit(photoDataUrl);
  }

  cotherPhoto() {
    const photoDataUrl = this.cropperElement.cropper.getCroppedCanvas().toDataURL()
    this.CropedOther.emit(true);
  }

  cancel() {
    this.CropedCancelled.emit();
  }
  
  disableScroll() {
    document.body.classList.add('no-scroll');
  }

  enableScroll() {
    document.body.classList.remove('no-scroll');
  }

  cropperOptions: any = {
    viewMode: 1,
    zoomable: true,
  scalable: true,
  };
  
  onCropperReady() {
    const cropper = this.cropperElement.cropper;


    if (cropper) {
      const cropperData = cropper.getCanvasData();
      
      const cropWidth = 316; 
      const cropHeight = 40; 
  
      // Calcula la posición centrada
      const left = (cropperData.width - cropWidth) / 2 + cropperData.left;
      const top = (cropperData.height - cropHeight) / 2 + cropperData.top;

      
      // Establece el área de recorte con tamaño y posición específicos
      cropper.setCropBoxData({
        left: left,  // Posición inicial en el eje X
        top: top,    // Posición inicial en el eje Y
        width: cropWidth, // Ancho del área de recorte
        height: cropHeight,// Alto del área de recorte
      });
    // Calcula el zoom necesario para ajustar la imagen de 1:1 a 4:3
    const targetAspectRatio = 4 / 3;
    const currentAspectRatio = cropperData.width / cropperData.height;

    let zoomFactor = 1;

    if (currentAspectRatio > targetAspectRatio) {
      // Si la imagen es más ancha que 4:3, aumenta el zoom en el ancho
      zoomFactor = targetAspectRatio / currentAspectRatio;
    } else {
      // Si la imagen es más alta que 4:3, aumenta el zoom en la altura
      zoomFactor = currentAspectRatio / targetAspectRatio;
    }

    // Aplica el zoom para simular una relación 4:3
    cropper.zoomTo(zoomFactor);
    }


  }
}