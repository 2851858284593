<app-navbar></app-navbar>
<app-buscar></app-buscar>


<div *ngIf="!loadig" class="row p-5">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>
<div>

</div>
<div *ngIf="loadig" class="row gx-4 gx-lg-5 text-left my-5">
  <nav
    style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
    aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="javascript:history.back()">Go to back</a></li>
      <li class="breadcrumb-item active" aria-current="page">Part</li>
    </ol>
  </nav>

  <div class="col-lg-7">

    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">

      <div class="carousel-inner">
        <div *ngFor="let prodImg of producto.pieImagenes;let i=index; first as isFirst"
          [ngClass]="isFirst ? 'carousel-item active' : 'carousel-item' ">
          <img class=" img-fluid rounded mb-4 mb-lg-0 fit-image d-block w-100" src="{{prodImg}}" alt="First slide">
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
        data-bs-slide="prev">
        <span class="btn btn-primary" aria-hidden="true"> <i class="fa fa-arrow-circle-left text-white"
            aria-hidden="true"></i></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
        data-bs-slide="next">
        <span class="btn btn-primary" aria-hidden="true"><i class="fa fa-arrow-circle-right text-white"
            aria-hidden="true"></i></span>
        <span class="visually-hidden">Next</span>
      </button>

    </div>


    <div class="d-flex flex-row bd-highlight mt-3">
      <div class="p-2 bd-highlight">
        <h4><span class="badge bg-warning"><i class="fa fa-location-arrow"></i>
            {{distancia}} M
          </span></h4>
      </div>
      <div class="p-2 bd-highlight">
        <h4><span class="badge bg-primary">
            {{producto.pieCondicion}}</span></h4>
      </div>
      <div class="p-2 bd-highlight">
        <h4><span class="badge bg-primary">
            {{producto.pieGarantia}}</span></h4>
      </div>
    </div>
    <div class="d-flex flex-row bd-highlight">
      <div class="p-2 bd-highlight">
        <h4 *ngIf="producto.pieCostoEnvio > 0; else free"><span class="badge bg-info ">Shipping:
            {{producto.pieCostoEnvio | currency }}</span></h4>
        <ng-template #free>
          <h4><span class="badge bg-info ">Shipping: Free</span></h4>
        </ng-template>
      </div>
      <div class="p-2 bd-highlight">
        <h4><span class="badge bg-info ">{{producto.PieTipoProducto}}</span></h4>
      </div>
    </div>

  </div>
  <div class="col-lg-5">
    <h3 class="font-weight-light">{{producto.pieNombre}}</h3>
    <hr>
    <h4 class="font-weight-light"><strong style="color: #008c44;">Price: </strong> {{producto.piePrecio | currency }}
    </h4>
    <h4 class="font-weight-light"><strong style="color: #008c44;">Mark:</strong> {{producto.marcaString}}</h4>
    <h4 class="font-weight-light"><strong style="color: #008c44;">Model: </strong> {{producto.modeloString}}</h4>
    <h4 class="font-weight-light"><strong style="color: #008c44;">Year: </strong><span
        *ngIf="producto.pieAnoDesde > 0">{{producto.pieAnoDesde}} - {{producto.pieAnoHasta}}</span> </h4>
    <br>
    <div *ngIf="producto.pieDisponible > 10">
      <h4 class="font-weight-light text-danger">In stock: {{producto.pieDisponible}}</h4>
    </div>
    <div *ngIf="producto.pieDisponible > 5 && producto.pieDisponible < 10">
      <h4 class="font-weight-light text-warning">In stock: {{producto.pieDisponible}}</h4>
    </div>
    <div *ngIf="producto.pieDisponible < 5">
      <h4 class="font-weight-light text-danger">In stock: {{producto.pieDisponible}}</h4>
    </div>
    <form>
      <div class="row g-3 align-items-center">
        <div class="col-auto">
          <label for="inputPassword6" class="col-form-label" style="color: #008c44;">
            <h4>Quantity</h4>
          </label>
          <div class="qty mt-2 text-left">
            <span class="minus bg-white">-</span>
            <input type="number" class="count" name="qty" id="cantPr" value="1">
            <span class="plus bg-white">+</span>
          </div>
        </div>
      </div>
    </form>
    <hr>
    <div class="d-flex flex-row bd-highlight mb-3">
      <div class="p-2 bd-highlight" *ngIf="producto.pieUsuID != UsuId && producto.empId != EmpId">
        <a *ngIf="btnGuardar; else btnGborrar" class="btn btn-primary text-white" (click)="postGuardar()"><i
            class="fa fa-heart"></i></a>
        <ng-template #btnGborrar>
          <a class="btn btn-danger text-white" (click)="postGuardar()"><i class="fa fa-heart"></i></a>
        </ng-template>
      </div>
      <div *ngIf="producto.pieUsuID != UsuId && producto.empId != EmpId" class="p-2 bd-highlight">
        <a class="btn btn-primary text-white"
          [routerLink]="['/chat', producto.pieId, producto.usuId, producto.pieNombre, producto.pieImagenes[0], 0]"><i
            class="fa fa-comment"></i> Contact seller</a>
      </div>
      <div *ngIf="producto.pieUsuID != UsuId && canCall == true && producto.empId != EmpId" class="p-2 bd-highlight">
        <a class="btn btn-success text-white" (click)="CallOwner()"><i class="me-1 fa fa-phone"></i> Call seller</a>
      </div>
      <!--   <div class="p-2 bd-highlight" *ngIf="producto.pieUsuID != UsuId && producto.pieTipoProducto != 3" >
             <a *ngIf="btnAgregar; else btnborrar" class="btn btn-primary text-white" (click)="postCarrito()">Add to cart</a>
             <ng-template #btnborrar>
            <a class="btn btn-danger text-white" (click)="borrarItem(producto)">Delete from cart</a>
          </ng-template>
        </div>-->
      <div *ngIf="producto.pieUsuID == UsuId || producto.empId == EmpId" class="p-2 bd-highlight">
        <a *ngIf="producto.pieDisponible > 0; else soldOut" class="btn btn-primary text-white" (click)="Vendida()">Mark
          as sold</a>
        <ng-template #soldOut>
          <div class="alert alert-danger" role="alert">
            Sold item!
          </div>
        </ng-template>
      </div>

      <div *ngIf="producto.pieUsuID == UsuId || producto.empId == EmpId" class="p-2 bd-highlight">
        <a class="btn btn-success text-white" [routerLink]="['/editpieza', producto.pieId]">Edit Part
        </a>
      </div>

    </div>
    <hr>
    <div class="d-flex flex-row bd-highlight border rounded-3 border-bottom shadow-sm">
      <div class="p-2 bd-highlight" *ngIf="producto.carId > 0">
        <a [routerLink]="['/carro', producto.carId,producto.pieNombre]">
          <div class="d-flex justify-content-start">
            <div class="p-2 bd-highlight mt-2"><img src="{{producto.carFoto}}"
                class="rounded-circle border border-primary" width="55" height="55"></div>
            <div class="p-2 bd-highlight mt-2">
              <h5 class="mt-2">Parts of car</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="p-2 bd-highlight" *ngIf="producto.pieUsuID != UsuId">
        <a [routerLink]="['/infoVendedor', producto.pieId]">
          <div class="d-flex justify-content-start">
            <div class="p-2 bd-highlight mt-2"><img src="{{infoOwner.fotoOwner}}"
                class="rounded-circle border border-primary" width="55" height="55"></div>
            <div class="p-2 bd-highlight">
              <h5 class="mt-2">{{infoOwner.nombreOwner}}
                <br>
                <span style="font-size: 25px;" class="text-secondary">{{infoOwner.rankingOwner}}</span>
              </h5>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="row border mb-5 p-5">
  <h3 class="font-weight-light text-center"><strong style="color: #008c44;">Description:</strong></h3>
  <p id="pDespc" class="p-3">
  </p>
</div>

<h3 class="mb-3 mr-5 text-left text-primary">Related: <i class="fa fa-arrow-right"></i> </h3>

<div *ngIf="!loadigrecon; else recon" class="row p-5">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>
<div>
  <ng-template #recon>

    <div class="live__scroll">
      <a class="marco" *ngFor="let resultado of resultados" [routerLink]="['/producto', resultado.pieId]">
        <div class="live__scroll--box">
          <div class="card pieza rounded-3">
              <img style="background-image: url(../../../assets/img/VP_GRIS.png);"
                class="card-img-top fit-imageRelacionados img-fluid" src="{{resultado.imagen1}}">
                <div class="piezaTextos d-inline-block">
                  <p class="Nombre-text">{{producto.pieNombre}}</p>
                  <p class="Precio-text">{{producto.piePrecio | currency}}</p>
                </div>          
          </div>

        </div>
      </a>
    </div>

  </ng-template>