<app-navbar></app-navbar>

<table class="table pb-5 mt-5">
  <tbody>
    <tr>
      <td colspan="6" style="width: 16%; ">
        <div class=" input-group mt-3">
          <input type="text" class="form-control"  id="tbxBuscar" placeholder="Search..." aria-label="Recipient's username" aria-describedby="button-addon2" (keydown.enter)="buscar()">
        </div>
      </td>
    </tr>
    <tr>

      <td> <div class="nav-item" style="position: relative;bottom: -25px;">
        <h3  class="text-primary">Filter  <i class="fa fa-filter"></i></h3>    
   </div></td>
      <td style="width: 16%;"><div class="nav-item mt-3"> 
        <h6 class="text-primary">Year</h6> 
          <select class="form-select" aria-label="Default select example" id="year">
              <option [value]="null" selected>Select year</option>
              <option *ngFor="let year of years" [value]="year">
                {{year}}
            </option>
          </select>   
        </div></td>
      <td style="width: 16%;">   <div class="nav-item mt-3 "> 
        <h6 class="text-primary ">Make</h6> 
          <select [disabled]="marListo" #idmarca class="form-select " aria-label="Default select example" id="marca"  (change)="cargarModelo(idmarca.value)">
            <option [value]="null" selected>Select make</option>
            <option *ngFor="let marcas of marca;" [value]="marcas.marDescripcion">
              {{marcas.marDescripcion}}
          </option>
          </select>   
        </div></td>
        <td style="width: 16%;"> <div class="nav-item mt-3"> 
          <h6 class="text-primary">Model</h6> 
            <select  class="form-select" aria-label="Default select example" id="modelo">
              <option [value]="null" selected>Select model</option>
              <option *ngFor="let modelos of modelo" [value]="modelos.marID">
                {{modelos.modDescripcion}}
            </option>
            </select>   
          </div> </td>
          <td style="width: 16%;">   <div class="nav-item mt-3"> 
            <h6 class="text-primary">Category</h6> 
              <select [disabled]="catListo" #idcat class="form-select" aria-label="Default select example" id="categorias" (change)="cargarPart(idcat.value)">
                <option selected [value]="null" >Select Category</option>
                <option *ngFor="let categorias of categoria" [value]="categorias.catID">
                  {{categorias.catDescripcion}}
              </option>
              <option *ngFor="let categorias of categoria" [value]="categorias.catID">
                {{categorias.catDescripcion}}
            </option>
              </select>   
            </div></td>
            <td style="width: 16%;">  <div class="nav-item mt-3"> 
              <h6 class="text-primary">Part</h6> 
                <select class="form-select" aria-label="Default select example" id="part" >
                  <option selected [value]="null" >Select Part</option>
                  <option *ngFor="let part of parts" [value]="part.catID">
                    {{part.piePreNombre}}
                </option>
                </select>   
              </div></td>
    </tr>
    <tr>

      <td style="width: 16%;"><div class="form-check mb-3">
        <input class="form-check-input" type="checkbox" value="true" id="CheckFreeshp">
        <label class="form-check-label" for="flexCheckDefault">
         Free Shipping
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="CheckNearby">
        <label class="form-check-label" for="flexCheckChecked">
            Nearby item 
        </label>
      </div></td>
      
      <td style="width: 16%; position: relative; right: -60%;" ><div class="nav-item mt-3">
        <button type="button" (click)="clean()" class="btn btn-warning text-white"> <i class="fa fa-eraser"></i> Clean filter </button>
      </div>
    </td>
       <td style="width: 16%; position: relative; right: -56%;"> 
        <div class="nav-item mt-3">
          <button type="button" (click)="buscar()" class="btn btn-primary text-white"> <i class="fa fa-search"></i> Search  </button>
       </div></td>
       
    </tr>
  </tbody>
</table>


 <div class="row flex-nowrap pb-5">
  
        
        <div class="col py-3">
          

          <div *ngIf="loading" class="row p-5">
            <div class="d-flex justify-content-center">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>

          <div *ngIf="noData" class="row p-5">
            <div class="d-flex justify-content-center">
              <h3>
                <span>
                  Data not found.</span>
              </h3>
              <img src="../../../assets/img/phvp.png" width="500" height="500" alt="">
            </div>
          </div>

          <div  *ngIf="result" class="row p-5">
            <div class="noCard" *ngFor="let resultado of resultados| paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count  }; let i = index">
              <div class="row g-0">
                <div class="col-md-4 ">
                  <img src="{{resultado.pieImagenes[0]}}" class="fit-image" alt="..." >
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <a [routerLink]="['/producto', resultado.pieId]"> <h3 class="card-title text-left" style="color: black">{{resultado.pieNombre}}</h3> </a>
                    <h3 class="text-left"><small class="text-muted">Shipping: {{resultado.pieCostoEnvio}}</small></h3>
                      <h3 class=" text-left font-weight-light text-primary">Price:<strong >Us{{resultado.piePrecio | currency }}</strong></h3>
                      <h3 class="text-left"><small class="text-muted">Stock: {{resultado.pieDisponible}}</small></h3>
                  </div>
                </div>
              </div>
            </div>

            <style>
              .my-pagination /deep/ .ngx-pagination .current {
                background: #008c44;
              }
            </style>
          <div class="col-12 mt-3 d-flex justify-content-center">
            <pagination-controls class="my-pagination"
            previousLabel="Anterior"
            nextLabel="Siguiente"
            [responsive]="true"
            (pageChange)="handlePageChange($event)"
          ></pagination-controls>   
            </div>

          </div>
        </div>
    </div>
 